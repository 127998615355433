<template>
  <div class="card" :class="classObject">
    <div v-if="$slots.header" class="card-header">
      <slot name="header"></slot>
    </div>
    <div class="card-body">
      <div v-if="title">{{ title }}</div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardComponent',
  components: {},
  props: {
    title: {
      type: String,
      default: '',
    },
    variant: {
      type: String,
      default: 'primary',
      validator(value, props) {
        return ['primary', 'secondary', 'tertiary'].includes(value);
      },
    },
    isBlock: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    classObject() {
      return [
        this.variant,
      ];
    },
  },
  created() {
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.card {
  &.tertiary {
    box-shadow: none;
    .card-header {
      border-bottom: 3px solid rgba(107, 35, 220, 0.2);
    }
  }
  .card-header {
    font-size: 20px;
    font-weight: 700;
    padding-top: 0;
    .actions.top-right {
      top: 0;
    }
  }
}
</style>
