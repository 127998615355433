<template>
<div>
  <template v-if="fetched">
    <!-- Task 1 -->
    <div class="show-tasks">
      <ul class="tasks-options list-unstyled">
        <li><button v-b-toggle.sidebar-right-show-add-prod class="btn btn-primary"><span class="material-symbols-outlined">add</span>{{$t('catalog.register.title')}}</button></li>
      </ul>
      <table class="table table-hover table-borderless">
        <thead>
          <tr>
            <th scope="col">ID</th>
            <th scope="col">{{$tc('app.product', 1)}}</th>
            <th scope="col">{{$t('app.recurrence')}}</th>
            <th scope="col">{{$t('app.description')}}</th>
            <th scope="col">{{$tc('app.value', 1)}}</th>
            <th scope="col">Quantidade</th>
            <th scope="col">{{$t('catalog.register.discount')}}</th>
            <th scope="col">Total</th>
          </tr>
        </thead>
        <products-list
          v-for="(product, index) in offerings"
          :key="product.pivot.id"
          :item="product"
          :index="index"
          @update="updatedProducts" />
      </table>
      <add-products-side-bar :item="currentTask" @update="updatedProducts"/>
    </div>
    <!--/Task 1 -->
    <!-- <pagination v-model="currentPage" :total-pages="pages" @change="fetch" /> -->
  </template>
  <template v-else>
    <div class="qt-block-ui"></div>
  </template>
</div>
</template>
<script>
import ProductsList from '@/components/rebranding/workflow/detail/list/ProductsList.vue';
import AddProductsSideBar from '@/components/rebranding/workflow/detail/list/AddProductsSideBar.vue';
// import Pagination from '@/components/rebranding/pagination/Pagination.vue';

import axios from '@/plugins/axios';

const webApi = axios();

export default {
  name: 'DealProducts',
  components: {
    ProductsList,
    AddProductsSideBar,
    // Pagination,
  },
  props: {
    users: {
      type: Array,
      default: () => ({}),
    },
    products: {
      type: Array,
      default: () => ({}),
    },
    currentTask: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      currentPage: 1,
      pages: 1,
      fetched: true,
      offerings: [],
      productItemTest: [
        {
          id: '0',
          title: 'Product 1',
          description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Ullam, aut. Eos aut minima consequatur voluptas quam, necessitatibus modi dignissimos numquam consectetur enim quis perferendis, autem perspiciatis voluptatibus mollitia aperiam magni.',
          created_at: '2023-10-03T19:30:44.000000Z',
          value: '14245',
          status: 'active',
          done: true,
        },
        {
          id: '1',
          title: 'Product 2',
          description: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quisquam tempora doloremque culpa, in saepe obcaecati odio a dolore itaque, ullam reprehenderit magnam nam tempore accusantium vero alias ducimus inventore quis.',
          created_at: '2023-10-03T17:14:47.000000Z',
          value: '777632',
          status: 'inactive',
          done: false,
        },
        {
          id: '2',
          title: 'Product 3',
          description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Beatae repellendus recusandae aut nam reiciendis ratione sequi accusamus iusto. Autem beatae laboriosam iure quam commodi odio maiores sint optio magni qui!',
          created_at: '2023-08-18T14:35:13.000000Z',
          value: '231',
          status: 'active',
          done: true,
        },
      ],
    };
  },
  methods: {
    updatedProducts() {
      this.fetch();
    },
    fetch() {
      this.fetched = false;
      webApi.get(`/pipelines/deals/${this.$route.params.id}`).then(
        (response) => {
          console.log('Product response:\n ', response.data);
          this.fetched = true;
          this.offerings = response.data.offerings;
        },
        (error) => {
          this.content = error;
        },
      );
    },
    /* fetchOfferings() {
      webApi.get(`/pipelines/deals/${this.$route.params.id}`).then(
        (response) => {
          this.offerings = response.data.offerings;
        },
        (error) => {
          this.content = error;
        },
      );
    }, */
    /* fetch(page) {
      this.fetched = false;
      this.currentPage = page;
      webApi.get(`/pipelines/offerings?page=${this.currentPage}`).then(
        (response) => {
          this.offerings = response.data.data;
          this.pages = response.data.last_page;
          console.log('this.offerings', this.offerings);
        },
        (error) => {
          this.content = error;
        },
      ).finally(() => {
        this.fetched = true;
      });
    }, */
  },
  mounted() {
    this.fetch();
  },
};
</script>
<style lang="scss" scoped>
::v-deep {
  .table-hover tbody tr:hover {
    color: #212529;
    background-color: #6b23dc14;
  }
}
.tasks-options {
  width: 100%;
  display: flex;
  margin-top: 1rem;
  margin-bottom: 0;
  padding: 0 1rem;
  button {
    display: flex;
    height: fit-content;
    align-items: center;
    gap: 5px;
    padding: .4rem 0.5rem .4rem;
    border-radius: 8px;
  }
}
.card-task {
  border-radius: 8px;
  flex: 0 0 31%;
  max-width: 31%;
  transition: 0.5s;
  margin-bottom: 0;
  height: fit-content;
  min-height: 210px;
  &:hover {
    transform: scale(1.02);
  }
  .card-header {
    display: flex;
    align-items: center;
    .options-task {
      margin-left: auto;
      .btn {
        width: fit-content;
        height: fit-content;
        padding: 0;
      }
      .material-symbols-outlined {
        border-radius: 50%;
        padding: 3px;
        &.done {
          background-color: var(--sent-bg) !important;
          border-color: var(--sent-fg) !important;
          color: var(--sent-fg) !important;
        }
        &.not-done {
          background-color: var(--urgent-bg) !important;
          border-color: var(--urgent-fg) !important;
          color: var(--urgent-fg) !important;
        }
      }
      ul {
        display: flex;
        align-items: center;
        gap: 5px;
        margin-bottom: 0;
      }
    }
  }
  .card-body {
    padding-top: 0;
  }
  .collapse-preview a.collapsed:after {
    content: '+ Show More';
  }

  .collapse-preview a:not(.collapsed):after {
    content: '- Show Less';
  }

  .collapse-preview.collapse:not(.show) {
    display: block;
    /* height = lineheight * no of lines to display */
    height: 4.5em;
    overflow: hidden;
  }
  .backdrop-preview {
    display: none;
  }
  .collapse-preview.collapse:not(.show) ~ .backdrop-preview {
    position: relative;
    display: block;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    height: 30px;
    margin-top: -30px;
  }

  .collapse-preview.collapsing {
    height: 4.5em;
  }
}
</style>
