<template>
    <b-sidebar id="sidebar-right-edit-task" ref="editTaskSide" :title="`${$t('app.edit')} ${taskTitle}`" no-header right backdrop shadow>
      <div class="col sidebar-header">
        <span v-b-toggle.sidebar-right-edit-task class="material-symbols-outlined arrow-back">keyboard_double_arrow_left</span>
        <p>{{$t('app.edit')}} <b>{{taskTitle}}</b></p>
        <!-- <span class="material-symbols-outlined">chevron_right</span>
        <p class="text-yup">{{columnTitle}}</p> -->
        <span v-b-toggle.sidebar-right-edit-task class="material-symbols-outlined sidebar-close">close</span>
      </div>
      <div class="col sidebar-item value-column">
        <span class="label-sidebar-item">{{$tc('app.title', 1)}}</span>
        <input class="form-control" v-model="taskTitle" type="text">
      </div>
      <div class="col sidebar-item value-column">
        <span class="label-sidebar-item">{{$t('app.description')}}</span>
        <input class="form-control" v-model="taskDescription" type="text">
      </div>
      <div class="col sidebar-item type-column">
        <div class="title-task">
          <span class="label-sidebar-item">Tipo</span>
          <select-input
            class="w-100"
            label="text"
            v-model="taskType"
            :reduce="(type) => type.value"
            :create-option="(type) => ({text: type, value: titleCase(type)})"
            :options="type"
            :clearable="false"
            editable
            taggable
            push-tags
          />
        </div>

      </div>
      <!-- <div class="col sidebar-item assign-user-column">
        <span class="label-sidebar-item">{{$t('workflow.workspace.card.assign')}}</span>
        <svg @click="addingUser = !addingUser" data-v-1c0b824d="" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="heroicon add"><path data-v-1c0b824d="" stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
        <select-input
          v-if="addingUser"
          multiple
          label="name"
          class="w-100 add-user"
          :reduce="(itemTask) => itemTask"
          v-model="userSelect"
          @input="addUser()"
          :options="users"
          :clearable="false"
        >
          <template #selected-option="{ name }">
            <div style="display: flex; align-items: baseline">
              <strong>{{ name }}</strong>
            </div>
          </template>
        </select-input>
        <div class="w-100 profile-wrapper">
          <template v-for="user in userSelect">
            <div
              class="profile"
              :key="`carduser:${user.id}`"
              :title="`teamuser:${user.id}`"
              :style="{ backgroundColor: $strToColor(user.name) }">
                {{user.name | initials}}
              </div>
          </template>
        </div>
      </div> -->
      <div class="col sidebar-item date-column">
        <span class="label-sidebar-item">Data de vencimento</span>
        <Calendar id="icon" ref="calendarDate" v-model="taskDate" :value="taskDate" dateFormat="dd/mm/yy" :showTime="true" :showIcon="true" />
        <!-- <code>{{taskDate}}</code> -->
      </div>
      <div class="col btn-wrapper">
        <button class="btn btn-outline-primary mt-3" v-b-toggle.sidebar-right-edit-task>{{$t('app.cancel')}}</button>
        <button class="btn btn-primary mt-3 ml-auto" @click="editTask()" :class="{'qt-loader qt-loader-mini qt-loader-right':isSending}" :disabled="isSending">{{$t('app.save')}}</button>
      </div>
    </b-sidebar>
</template>
<script>
// Axios
import axios from '@/plugins/axios';

// Primevue
import 'primevue/resources/themes/saga-blue/theme.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'primevue/resources/primevue.min.css';
import Calendar from 'primevue/calendar';

// Select
import SelectInput from '@/components/SelectInput.vue';

const webApi = axios();

export default {
  name: 'TasksSideBarEdit',
  components: {
    SelectInput,
    Calendar,
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    /* users: {
      type: Array,
      default: () => ({}),
    }, */
  },
  watch: {
    item: {
      handler(val, oldVal) {
        // console.log('Watch: item watch: ', val, '\nWatch: item old value: ', oldVal);
        this.taskTitle = this.item.title;
        this.taskDescription = this.item.description;
        this.taskType = this.item.type;
        this.taskDate = new Date(this.item.due_date);
      },
      deep: true,
      immediate: false,
    },
  },
  filters: {
    initials(name) {
      let [f, l] = name.replace(/\s(do|de|da)\s/gi, ' ').split(/\s+/, 2);

      f = `${f || ' '}`.toUpperCase();
      l = `${l || ' '}`.toUpperCase();

      return `${f[0]}${l[0]}`;
    },
  },
  data() {
    return {
      addingUser: false,
      taskOwner: '',
      taskTitle: '',
      taskDescription: '',
      taskType: '',
      taskStatus: '',
      taskDate: '',
      timeout: null,
      searchQuery: '',
      owners: [],
      // type: [{ text: 'Tarefa', value: 'task' }, { text: 'Normal', value: 'medium' }, { text: 'Alto', value: 'high' }, { text: 'Urgente', value: 'urgent' }],
      type: [
        { text: 'Telefonar', value: 'call' },
        { text: 'Enviar SMS', value: 'sms' },
        { text: 'Enviar e-mail', value: 'mail' },
      ],

      isSending: false,

      // userAndDepCard: [],
      userSelect: '',
      userTaskEdit: [],
    };
  },
  methods: {
    titleCase(str) {
      return str.toLowerCase().replace(/./, (x) => x.toUpperCase()).replace(/[^']\b\w/g, (y) => y.toUpperCase().replace(/ /g, '')); // After every space, capitalize next Char and remove spaces
    },
    editTask() {
      if (!this.taskTitle) {
        this.$toast.show({
          title: `${this.$t('sms.infos.filters.status.lbl-error')}`,
          content: 'Insira um título!',
          type: 'danger',
        });
      } else if (!this.taskDescription) {
        this.$toast.show({
          title: `${this.$t('sms.infos.filters.status.lbl-error')}`,
          content: 'Insira uma descrição!',
          type: 'danger',
        });
      } else if (!this.taskType) {
        this.$toast.show({
          title: `${this.$t('sms.infos.filters.status.lbl-error')}`,
          content: 'Campo tipo é obrigatório!',
          type: 'danger',
        });
      } else if (!this.taskDate) {
        this.$toast.show({
          title: `${this.$t('sms.infos.filters.status.lbl-error')}`,
          content: 'Campo data é obrigatório!',
          type: 'danger',
        });
      } else {
        this.isSending = true;
        const date = this.handleCalendar();
        webApi.put(`/pipelines/tasks/${this.item.id}`, {
          title: this.taskTitle,
          description: this.taskDescription,
          due_date: date,
          type: this.taskType,
        }).then(
          (response) => {
            this.$emit('update');
          },
          (error) => {
            console.error(`Comments: error ->\n${error}`);
            this.content = error;
            this.$toast.show({
              title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
              content: error.message,
              type: 'danger',
            });
          },
        ).finally(() => {
          this.taskTitle = '';
          this.taskDescription = '';
          this.taskType = '';
          this.taskDate = '';
          this.isSending = false;
          this.$root.$emit('refreshLogList'); // Refresh deal log list
        });
      }
    },
    fetchUsersDepBoard(search, isEdit) {
      clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        if (isEdit) {
          this.userAndDepCardEdit = [];
        } else {
          this.userAndDepCard = [];
        }

        webApi.get(`/workflows/boards/${this.$route.params.id}/team?query=${encodeURIComponent(search)}`).then(
          (response) => {
            console.log('Fetch user n dep response: ', response, 'query: ', search);
            if (isEdit) {
              this.userAndDepCardEdit = response.data;
            } else {
              this.userAndDepCard = response.data;
            }
            console.log('Users array: ', this.userAndDepCard);
          },
          (error) => {
            this.content = error;
          },
        );
      }, 1000);
    },
    handleCalendar() {
      return this.taskDate.toISOString();
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep{
  .vs__selected {
    background-color: var(--clr-yup-purple-hover-transparent);
    border-color: var(--clr-yup-purple-hover-transparent);
    border-radius: 8px;
    color: var(--clr-yup-purple);
  }
  button.vs__deselect span {
    color: var(--clr-yup-purple);
  }
}
.profile-wrapper {
  display: flex;
  .profile {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    flex-shrink: 0;
    overflow: hidden;
    box-shadow: 0 0 0 4px var(--background-2);
    pointer-events: none;
    color: #fff;
    svg {
      width: 20px;
      height: 20px;
    }

    &.faded-icon {
      background-color: var(--c-grey-100);
      color: var(--c-grey-400);
    }

    &.filled-icon {
      background-color: var(--c-blue-500);
      color: #fff;
    }
  }
}
</style>
