<template>
    <div>
      <b-sidebar id="sidebar-right-show-add-prod" ref="addTaskSide" :title="'Adicionar produto'" no-header right backdrop shadow>
        <div class="col sidebar-header">
          <span v-b-toggle.sidebar-right-add-products class="material-symbols-outlined arrow-back">keyboard_double_arrow_left</span>
          <p>Adicionar produto</p>
          <span v-b-toggle.sidebar-right-add-products class="material-symbols-outlined sidebar-close">close</span>
        </div>
        <div class="col sidebar-item offering-column">
          <span class="label-sidebar-item">{{$t('workflow.workspace.products.sidebar.title')}}</span>
          <select-input
            class="w-100"
            label="title"
            v-model="offering.id"
            :reduce="(entry) => entry.id"
            :options="offerings"
            :clearable="false"
            :editable="false"
          >
            <template #list-header>
              <li><button v-b-toggle.sidebar-right-add-products class="btn btn-outline-primary w-100 text-center mb-2">{{$t('catalog.register.title')}}</button></li>
            </template>
          </select-input>

          <div class="form-group">
            <label for="quantityInput">{{$t('app.quantity')}}</label>
            <input
              min="1"
              type="number"
              class="form-control"
              id="quantityInput"
              aria-describedby="quantityHelp"
              :placeholder="$t('workflow.workspace.products.sidebar.title.qt-desc')"
              :disabled="!offering.id"
              v-model="offering.quantity">
          </div>

          <div class="form-group">
            <label for="recurrenceInput">{{$t('app.recurrence')}}:</label>
            <select
              class="form-control"
              id="recurrenceInput"
              aria-describedby="recurrenceHelp"
              :disabled="!offering.id"
              v-model="offering.recurrence">
              <option value="single">{{$t('app.unique')}}</option>
              <option value="recurrent">{{$t('app.recurrent')}}</option>
            </select>
          </div>

          <div class="form-group">
            <label for="discountInput">{{$t('catalog.register.discount')}}:</label>
            <input
              min="0"
              type="number"
              class="form-control"
              id="discountInput"
              aria-describedby="discountHelp"
              :placeholder="$t('workflow.workspace.products.sidebar.discount-desc')"
              :disabled="!offering.id"
              v-model="offering.discount">
          </div>
        </div>
        <div class="col btn-wrapper">
          <button class="btn btn-outline-primary mt-3" v-b-toggle.sidebar-right-show-add-prod>{{$t('app.cancel')}}</button>
          <button class="btn btn-primary mt-3 ml-auto" @click="addProdDeal()" :class="{'qt-loader qt-loader-mini qt-loader-right':isSending}" :disabled="isSending">{{$t('app.save')}}</button>
        </div>
      </b-sidebar>
      <products-side-bar @update="fetchOfferings" />
    </div>
</template>
<script>
import ProductsSideBar from '@/components/rebranding/workflow/detail/list/ProductsSideBar.vue';

// Axios
import axios from '@/plugins/axios';

import 'bootstrap-vue/dist/bootstrap-vue.css';
import $ from 'jquery';

// Select
import SelectInput from '@/components/SelectInput.vue';

const webApi = axios();

export default {
  name: 'TasksSideBar',
  components: {
    SelectInput,
    ProductsSideBar,
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  filters: {
    initials(name) {
      let [f, l] = name.replace(/\s(do|de|da)\s/gi, ' ').split(/\s+/, 2);

      f = `${f || ' '}`.toUpperCase();
      l = `${l || ' '}`.toUpperCase();

      return `${f[0]}${l[0]}`;
    },
  },
  data() {
    return {
      addingUser: false,
      taskOwner: '',
      taskTitle: '',
      taskDescription: '',
      taskAmount: '',
      taskSubject: '',
      taskType: '',
      taskStatus: '',
      taskDate: '',
      timeout: null,
      searchQuery: '',
      owners: [],
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: '',
        precision: 2,
        masked: false,
      },

      isSending: false,

      // userAndDepCard: [],
      userSelect: '',
      userTaskEdit: [],
      offerings: [],
      offering: {
        id: null,
        recurrence: 'single',
        discount: 0,
        quantity: 1,
      },
    };
  },
  methods: {
    addProdDeal() {
      if (!this.offering.id) {
        this.$toast.show({
          title: `${this.$t('sms.infos.filters.status.lbl-error')}`,
          content: 'Escolha um produto!',
          type: 'danger',
        });
      } else {
        const offering = { ...this.offering };
        offering.attach = true;
        this.isSending = true;
        webApi.put(`/pipelines/deals/${this.item.id}`, {
          offerings: [offering],
        }).then(
          (response) => {
            console.log('Edit task response: ', response);
            this.$toast.show({
              title: this.$t('generic-str.success'),
              content: this.$t('workflow.workspace.card.edit.success'),
              type: 'success',
            });
            this.toggleSidebar('sidebar-right-show-add-prod');
            this.$emit('update');
            // this.fetch();
          },
          (error) => {
            this.isSending = false;
            this.fetched = true;
            console.error({ error });
            this.content = error;
            this.$toast.show({
              title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
              content: error.message,
              type: 'danger',
            });
          },
        ).finally(() => {
          this.isSending = false;
          this.offering = {
            id: null,
            recurrence: 'single',
            discount: 0,
            quantity: 1,
          };
        });
      }
    },
    fetchOfferings() {
      webApi.get('/pipelines/offerings').then(
        (response) => {
          this.offerings = response.data;
          console.log('this.offerings', this.offerings);
        },
        (error) => {
          this.content = error;
        },
      );
    },
    toggleSidebar(id) {
      this.$root.$emit('bv::toggle::collapse', id);
    },
  },
  mounted() {
    this.fetchOfferings();
  },
};
</script>
<style lang="scss" scoped>
::v-deep{
  .vs__selected {
    background-color: var(--clr-yup-purple-hover-transparent);
    border-color: var(--clr-yup-purple-hover-transparent);
    border-radius: 8px;
    color: var(--clr-yup-purple);
  }
  button.vs__deselect span {
    color: var(--clr-yup-purple);
  }
}
.profile-wrapper {
  display: flex;
  .profile {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    flex-shrink: 0;
    overflow: hidden;
    box-shadow: 0 0 0 4px var(--background-2);
    pointer-events: none;
    color: #fff;
    svg {
      width: 20px;
      height: 20px;
    }

    &.faded-icon {
      background-color: var(--c-grey-100);
      color: var(--c-grey-400);
    }

    &.filled-icon {
      background-color: var(--c-blue-500);
      color: #fff;
    }
  }
}
.card-task {
  border-radius: 8px;
  flex: 0 0 31%;
  max-width: 31%;
  transition: 0.5s;
  margin-bottom: 0;
  height: fit-content;
  min-height: 210px;
  &:hover {
    transform: scale(1.02);
  }
  .card-header {
    display: flex;
    align-items: center;
    .options-task {
      margin-left: auto;
      .btn {
        width: fit-content;
        height: fit-content;
        padding: 0;
      }
      .material-symbols-outlined {
        border-radius: 50%;
        padding: 3px;
        &.done {
          background-color: var(--sent-bg) !important;
          border-color: var(--sent-fg) !important;
          color: var(--sent-fg) !important;
        }
        &.not-done {
          background-color: var(--urgent-bg) !important;
          border-color: var(--urgent-fg) !important;
          color: var(--urgent-fg) !important;
        }
      }
      ul {
        display: flex;
        align-items: center;
        gap: 5px;
        margin-bottom: 0;
      }
    }
  }
  .card-body {
    padding-top: 0;
  }
  .collapse-preview a.collapsed:after {
    content: '+ Show More';
  }

  .collapse-preview a:not(.collapsed):after {
    content: '- Show Less';
  }

  .collapse-preview.collapse:not(.show) {
    display: block;
    /* height = lineheight * no of lines to display */
    height: 4.5em;
    overflow: hidden;
  }
  .backdrop-preview {
    display: none;
  }
  .collapse-preview.collapse:not(.show) ~ .backdrop-preview {
    position: relative;
    display: block;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    height: 30px;
    margin-top: -30px;
  }

  .collapse-preview.collapsing {
    height: 4.5em;
  }
}
</style>
