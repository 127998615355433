<template>
  <!-- Task 1 -->
  <div class="show-tasks">
    <ul class="tasks-options list-unstyled">
      <!-- <li><span class="title">Lembretes</span><button v-b-toggle.sidebar-right-add-task class="btn btn-outline-primary bg-transparent ml-auto border-0"><span class="material-symbols-outlined">add_alert</span>{{$t('generic-str.add')}} lembrete</button></li> -->
      <li><span class="title">Lembretes</span><button @click="addTaskCard = !addTaskCard" class="btn btn-outline-primary bg-transparent ml-auto border-0"><span class="material-symbols-outlined">add_alert</span>{{$t('generic-str.add')}} lembrete</button></li>
    </ul>
    <template v-if="fetched">
      <TasksCardAdd v-if="addTaskCard" @update="fetch(1, ...arguments)" :countTasks="countTasks" @cancel="hideAddTask()" />
      <template v-for="(detailTask, index) in taskItems">
        <tasks-list v-if="detailTask" :key="detailTask.id" :item='detailTask' :index='index' @update="fetch(1, ...arguments)" @taskToEdit="fillCurrentTask" />
      </template>
      <pagination v-model="currentPage" :total-pages="pages" @change="fetch" />
      <!-- <tasks-side-bar :users="users" @update="fetch(1)" /> -->
      <tasks-side-bar-edit :item="currentTask" @update="fetch(1)" />
    </template>
    <template v-else>
      <template v-for="index in taskCounter">
        <div class="card card-task dummy-card" :key="index">
          <div class="card-header">
            <div class="task-title animated-background dummy-scale"></div>
          </div>
          <div class="card-body">
            <div class="task-description animated-background dummy-scale" v-for="i in 4" :key="i"></div>
          </div>
          <div class="card-footer">
            <ul class="list-unstyled">
              <li><button class="btn btn-done animated-background dummy-scale"></button></li>
              <li><button class="btn btn-edit animated-background dummy-scale"></button></li>
            </ul>
          </div>
        </div>
      </template>
      <!-- <div class="loading w-100 concluded-loading">
        <LoadingAnim />
      </div> -->
      <!-- <div class="qt-block-ui qt-block-ui-rebrand"></div> -->
      <!-- <div class="loading-wrapper">
        <LoadingAnim />
      </div> -->
    </template>
  </div>
  <!--/Task 1 -->
</template>
<script>
import TasksList from '@/components/rebranding/workflow/detail/list/TasksList.vue';
/* import TasksSideBar from '@/components/rebranding/workflow/detail/list/TasksSideBar.vue'; */
import TasksSideBarEdit from '@/components/rebranding/workflow/detail/list/TasksSideBarEdit.vue';
import TasksCardAdd from '@/components/rebranding/workflow/detail/list/TasksCardAdd.vue';
import Pagination from '@/components/rebranding/pagination/Pagination.vue';
import axios from '@/plugins/axios';
// import LoadingAnim from '@/components/rebranding/animLottie/Loading.vue';

const webApi = axios();

export default {
  name: 'TaskDetail',
  components: {
    TasksList,
    /* TasksSideBar, */
    TasksSideBarEdit,
    Pagination,
    // LoadingAnim,
    TasksCardAdd,
  },
  props: {
    /* users: {
      type: Array,
      default: () => ({}),
    }, */
    countTasks: {
      type: Number,
      default: () => ({}),
    },
  },
  data() {
    return {
      currentPage: 1,
      pages: 0,
      taskCounter: 0,
      fetched: false,
      currentTask: null,
      taskItems: [],
      addTaskCard: false,
      taskItemTest: [
        {
          id: '0',
          title: 'Task 1',
          description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Ullam, aut. Eos aut minima consequatur voluptas quam, necessitatibus modi dignissimos numquam consectetur enim quis perferendis, autem perspiciatis voluptatibus mollitia aperiam magni.',
          created_at: '2023-10-03T19:30:44.000000Z',
          owner: 'Guilherme',
          done: true,
        },
        {
          id: '1',
          title: 'Task 2',
          description: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quisquam tempora doloremque culpa, in saepe obcaecati odio a dolore itaque, ullam reprehenderit magnam nam tempore accusantium vero alias ducimus inventore quis.',
          created_at: '2023-10-03T17:14:47.000000Z',
          owner: 'Erico',
          done: false,
        },
        {
          id: '2',
          title: 'Task 3',
          description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Beatae repellendus recusandae aut nam reiciendis ratione sequi accusamus iusto. Autem beatae laboriosam iure quam commodi odio maiores sint optio magni qui!',
          created_at: '2023-08-18T14:35:13.000000Z',
          owner: 'Vinícius',
          done: true,
        },
      ],
    };
  },
  methods: {
    hideAddTask() {
      this.addTaskCard = false;
    },
    fetch(page, taskAmount) {
      this.fetched = false;
      this.addTaskCard = false;
      this.currentPage = page;

      const id = this.$route.params.id;

      console.log('tasks', id);

      webApi.get(`/pipelines/deals/${id}/tasks?page=${this.currentPage}`).then(
        (response) => {
          this.taskItems = response.data.data;
          this.pages = response.data.last_page;
        },
        (error) => {
          this.$toast.show({
            title: 'Erro ao consultar',
            content: error.message,
            type: 'danger',
          });
        },
      ).finally(() => {
        if (taskAmount) {
          this.taskCounter += taskAmount;
        }
        this.fetched = true;
      });
    },
    fillCurrentTask(item) {
      this.currentTask = item;
      this.toggleSidebar('sidebar-right-edit-task');
    },
    toggleSidebar(id) {
      this.$root.$emit('bv::toggle::collapse', id);
    },
  },
  mounted() {
    this.taskCounter = this.countTasks;
    this.fetch(1);
  },
};
</script>
<style lang="scss" scoped>
.show-tasks {
  margin-bottom: 1rem;
}
.tasks-options {
  width: 100%;
  display: flex;
  margin-top: 1rem;
  margin-bottom: 0;
  // padding: 0 1rem;
  li {
    display: flex;
    align-items: center;
    width: 100%;
    > span.title {
      font-weight: 400;
      font-size: 1.25rem;
    }
  }
  button {
    display: flex;
    height: fit-content;
    align-items: center;
    gap: 5px;
    padding: .4rem 0.5rem .4rem;
    border-radius: 8px;
  }
}

// Dummy task
.dummy-card.card-task {
  max-height: 100px;
  .card-body {
    display: flex;
    gap: .5rem;
  }
  .task-title.animated-background {
    height: 24px;
    width: 44px;
  }
  .task-description.animated-background {
    height: 24px;
    width: 20%;
    &.task-description:nth-of-type(2n).animated-background {
      width: 15%;
    }
  }
  button.dummy-scale.animated-background {
    height: 24px;
    width: 60px;
    pointer-events: none;
  }
}
.card-task {
  border-radius: 8px;
  flex: 0 0 100%;
  max-width: 100%;
  transition: 0.5s;
  margin-bottom: 0;
  height: fit-content;
  min-height: auto;
  border-left: 5px solid var(--clr-yup-purple);
  &:hover {
    // transform: scale(1.02);
    box-shadow: 0px 3px 15px #0000001A;
  }
  .card-header {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    padding: .5rem;
    padding-left: .25rem;
    .options-task {
      margin-left: auto;
      small {
        display: flex;
        align-items: center;
        color: var(--gray-500);
        .material-symbols-outlined {
          margin-right: .25rem;
          font-size: .85rem;
        }
      }
      .btn {
        width: fit-content;
        height: fit-content;
        padding: 0;
        .material-symbols-outlined {
          border-radius: 50%;
          padding: 5px;
          font-size: 1.25rem;
        }
      }
      .material-symbols-outlined {
        &.done {
          background-color: var(--sent-bg) !important;
          border-color: var(--sent-fg) !important;
          color: var(--sent-fg) !important;
        }
        &.not-done {
          background-color: var(--normal-bg) !important;
          border-color: var(--normal-fg) !important;
          color: var(--normal-fg) !important;
        }
        &.expired {
          background-color: var(--urgent-bg) !important;
          border-color: var(--urgent-fg) !important;
          color: var(--urgent-fg) !important;
        }
      }
      ul {
        display: flex;
        align-items: center;
        position: relative;
        gap: 5px;
        margin-bottom: 0;
        .btn-delete-wrapper {
          position: absolute;
          top: -25px;
          right: -23px;
          .btn {
            opacity: 0;
          }
        }
      }
    }
  }
  &:hover {
    .btn-delete-wrapper {
      .btn {
        opacity: 1 !important;
      }
    }
  }
  .card-body {
    padding: .5rem;
    padding-left: .25rem;
    padding-top: 0;
    padding-bottom: 0;
    flex-basis: unset;
    flex-grow: initial;
    .task-description, .toggle-custom {
      font-size: .75rem;
    }
    .toggle-custom {
        .material-symbols-outlined {
        margin-left: 0;
        margin-right: 5px;
        transition: .5s;
        &.rotate {
          transform: rotate(-180deg);
        }
      }
    }
  }
  .card-footer {
    padding: .5rem;
    padding-left: .25rem;
    padding-top: .45rem;
    padding-bottom: .25rem;
    .list-unstyled {
      display: flex;
      gap: .5rem;
      margin-bottom: 0;
      .btn {
        color: #fff;
        font-weight: 400;
        border-radius: 8px;
        padding: .25rem;
      }
    }
    .btn-done {
      background-color: var(--sent-fg);
    }
    .btn-undone {
      background-color: var(--normal-fg);
    }
    .btn-edit {
      background-color: var(--rejected-fg);
    }
  }
  .collapse-preview a.collapsed:after {
    content: '+ Show More';
  }

  .collapse-preview a:not(.collapsed):after {
    content: '- Show Less';
  }

  .collapse-preview.collapse:not(.show) {
    display: block;
    overflow: hidden;
  }
  .backdrop-preview {
    display: none;
  }
  .collapse-preview.collapse:not(.show) ~ .backdrop-preview {
    position: relative;
    display: block;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      var(--background-2) 100%
    );
    height: 30px;
    margin-top: -30px;
  }

  .collapse-preview.collapsing, .collapse-preview.collapse:not(.show) {
    /* height = lineheight * no of lines to display */
    height: 1.8em;
  }
}
</style>
