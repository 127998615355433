<template>
    <div class="card card-task" id="card-right-add-task" ref="addTaskSide">
      <div class="card-body">
        <div class="col card-item date-column">
          <span class="label-sidebar-item">{{$t('workflow.workspace.board.final-dt')}}</span>
          <Calendar id="icon" ref="calendarDate" v-model="taskDate" :value="taskDate" dateFormat="dd/mm/yy" :showTime="true" :showIcon="true" />
        </div>
        <div class="col card-item value-column">
          <span class="label-sidebar-item">{{$tc('app.title', 1)}}</span>
          <input class="form-control" v-model="taskTitle" type="text">
        </div>
        <div class="col card-item type-column">
          <div class="title-task">
            <span class="label-sidebar-item">{{$t('app.type')}}</span>
            <select-input
              class="w-100"
              label="text"
              v-model="taskType"
              :reduce="(type) => type.value"
              :create-option="(type) => ({text: type, value: titleCase(type)})"
              :options="type"
              :clearable="false"
              :editable="false"
              :taggable="false"
              :push-tags="false"
            />
          </div>
        </div>
        <div class="col card-item value-column">
          <span class="label-sidebar-item">{{$t('app.description')}}</span>
          <input class="form-control" v-model="taskDescription" type="text">
        </div>
        <!-- <div class="col card-item assign-user-column">
          <span class="label-sidebar-item">{{$t('workflow.workspace.card.assign')}}</span>
          <svg @click="addingUser = !addingUser" data-v-1c0b824d="" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="heroicon add"><path data-v-1c0b824d="" stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
          <select-input
            v-if="addingUser"
            multiple
            label="name"
            class="w-100 add-user"
            :reduce="(itemTask) => itemTask"
            v-model="userSelect"
            @input="addUser()"
            :options="users"
            :clearable="false"
          >
            <template #selected-option="{ name }">
              <div style="display: flex; align-items: baseline">
                <strong>{{ name }}</strong>
              </div>
            </template>
          </select-input>
          <div class="w-100 profile-wrapper">
            <template v-for="user in userSelect">
              <div
                class="profile"
                :key="`carduser:${user.id}`"
                :title="`teamuser:${user.id}`"
                :style="{ backgroundColor: $strToColor(user.name) }">
                  {{user.name | initials}}
                </div>
            </template>
          </div>
        </div> -->
        <div class="col btn-wrapper">
          <button class="btn btn-outline-primary" @click="cancelAddTask()">{{$t('app.cancel')}}</button>
          <button class="btn btn-primary ml-auto" @click="addTask()" :class="{'qt-loader qt-loader-mini qt-loader-right':isSending}" :disabled="isSending">{{$t('app.save')}}</button>
        </div>
      </div>
    </div>
</template>
<script>
// Axios
import axios from '@/plugins/axios';

// Primevue
import 'primevue/resources/themes/saga-blue/theme.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'primevue/resources/primevue.min.css';
import Calendar from 'primevue/calendar';

// Select
import SelectInput from '@/components/SelectInput.vue';

const webApi = axios();

export default {
  name: 'TasksSideBar',
  components: {
    SelectInput,
    Calendar,
  },
  /* props: {
    users: {
      type: Array,
      default: () => ({}),
    },
  }, */
  filters: {
    initials(name) {
      let [f, l] = name.replace(/\s(do|de|da)\s/gi, ' ').split(/\s+/, 2);

      f = `${f || ' '}`.toUpperCase();
      l = `${l || ' '}`.toUpperCase();

      return `${f[0]}${l[0]}`;
    },
  },
  data() {
    return {
      addingUser: false,
      taskOwner: '',
      taskTitle: '',
      taskDescription: '',
      taskType: '',
      taskStatus: '',
      taskDate: '',
      timeout: null,
      searchQuery: '',
      owners: [],
      // type: [{ text: 'Tarefa', value: 'task' }, { text: 'Normal', value: 'medium' }, { text: 'Alto', value: 'high' }, { text: 'Urgente', value: 'urgent' }],
      type: [
        { text: 'Telefonar', value: 'call' },
        { text: 'Enviar SMS', value: 'sms' },
        { text: 'Enviar e-mail', value: 'mail' },
      ],

      isSending: false,

      // userAndDepCard: [],
      userSelect: '',
      userTaskEdit: [],
    };
  },
  methods: {
    titleCase(str) {
      return str.toLowerCase().replace(/./, (x) => x.toUpperCase()).replace(/[^']\b\w/g, (y) => y.toUpperCase().replace(/ /g, '')); // After every space, capitalize next Char and remove spaces
    },
    cancelAddTask() {
      this.taskTitle = '';
      this.taskDescription = '';
      this.taskType = '';
      this.taskDate = '';
      this.isSending = false;
      this.$emit('cancel');
    },
    addTask() {
      if (!this.taskTitle) {
        this.$toast.show({
          title: `${this.$t('sms.infos.filters.status.lbl-error')}`,
          content: 'Insira um título!',
          type: 'danger',
        });
      } else if (!this.taskDescription) {
        this.$toast.show({
          title: `${this.$t('sms.infos.filters.status.lbl-error')}`,
          content: 'Insira uma descrição!',
          type: 'danger',
        });
      } else if (!this.taskType) {
        this.$toast.show({
          title: `${this.$t('sms.infos.filters.status.lbl-error')}`,
          content: 'Campo tipo é obrigatório!',
          type: 'danger',
        });
      } else if (!this.taskDate) {
        this.$toast.show({
          title: `${this.$t('sms.infos.filters.status.lbl-error')}`,
          content: 'Campo data é obrigatório!',
          type: 'danger',
        });
      } else {
        this.isSending = true;
        const date = this.handleCalendar();
        webApi.post('/pipelines/tasks', {
          deal: this.$route.params.id,
          title: this.taskTitle,
          description: this.taskDescription,
          due_date: date,
          type: this.taskType,
        }).then(
          (response) => {
            this.$emit('update', 1);
          },
          (error) => {
            console.error(`Comments: error ->\n${error}`);
            this.content = error;
            this.$toast.show({
              title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
              content: error.message,
              type: 'danger',
            });
          },
        ).finally(() => {
          this.taskTitle = '';
          this.taskDescription = '';
          this.taskType = '';
          this.taskDate = '';
          this.isSending = false;
          this.$root.$emit('refreshLogList'); // Refresh deal log list
        });
      }
    },
    fetchUsersDepBoard(search, isEdit) {
      clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        if (isEdit) {
          this.userAndDepCardEdit = [];
        } else {
          this.userAndDepCard = [];
        }

        webApi.get(`/workflows/boards/${this.$route.params.id}/team?query=${encodeURIComponent(search)}`).then(
          (response) => {
            console.log('Fetch user n dep response: ', response, 'query: ', search);
            if (isEdit) {
              this.userAndDepCardEdit = response.data;
            } else {
              this.userAndDepCard = response.data;
            }
            console.log('Users array: ', this.userAndDepCard);
          },
          (error) => {
            this.content = error;
          },
        );
      }, 1000);
    },
    handleCalendar() {
      return this.taskDate.toISOString();
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep{
  .vs__selected {
    background-color: var(--clr-yup-purple-hover-transparent);
    border-color: var(--clr-yup-purple-hover-transparent);
    border-radius: 8px;
    color: var(--clr-yup-purple);
  }
  button.vs__deselect span {
    color: var(--clr-yup-purple);
  }
}
.profile-wrapper {
  display: flex;
  .profile {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    flex-shrink: 0;
    overflow: hidden;
    box-shadow: 0 0 0 4px var(--background-2);
    pointer-events: none;
    color: #fff;
    svg {
      width: 20px;
      height: 20px;
    }

    &.faded-icon {
      background-color: var(--c-grey-100);
      color: var(--c-grey-400);
    }

    &.filled-icon {
      background-color: var(--c-blue-500);
      color: #fff;
    }
  }
}
.card-task {
  border-radius: 8px;
  flex: 0 0 100%;
  max-width: 100%;
  transition: 0.5s;
  margin-bottom: 1rem;
  height: fit-content;
  min-height: auto;
  border-left: 5px solid var(--clr-yup-purple);
  &:hover {
    // transform: scale(1.02);
    box-shadow: 0px 3px 15px #0000001A;
  }
  .card-header {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    padding: .5rem;
    padding-left: .25rem;
    .options-task {
      margin-left: auto;
      small {
        display: flex;
        align-items: center;
        color: var(--gray-500);
        .material-symbols-outlined {
          margin-right: .25rem;
          font-size: .85rem;
        }
      }
      .btn {
        width: fit-content;
        height: fit-content;
        padding: 0;
        .material-symbols-outlined {
          border-radius: 50%;
          padding: 5px;
          font-size: 1.25rem;
        }
      }
      .material-symbols-outlined {
        &.done {
          background-color: var(--sent-bg) !important;
          border-color: var(--sent-fg) !important;
          color: var(--sent-fg) !important;
        }
        &.not-done {
          background-color: var(--normal-bg) !important;
          border-color: var(--normal-fg) !important;
          color: var(--normal-fg) !important;
        }
        &.expired {
          background-color: var(--urgent-bg) !important;
          border-color: var(--urgent-fg) !important;
          color: var(--urgent-fg) !important;
        }
      }
      ul {
        display: flex;
        align-items: center;
        position: relative;
        gap: 5px;
        margin-bottom: 0;
        .btn-delete-wrapper {
          position: absolute;
          top: -25px;
          right: -23px;
        }
      }
    }
  }
  .card-body {
    padding: .5rem;
    padding-left: .25rem;
    padding-top: 0;
    padding-bottom: 0;
    flex-basis: unset;
    flex-grow: initial;
    .task-description, .toggle-custom {
      font-size: .75rem;
    }
    .toggle-custom {
        .material-symbols-outlined {
        margin-left: 0;
        margin-right: 5px;
        transition: .5s;
        &.rotate {
          transform: rotate(-180deg);
        }
      }
    }
  }
  .card-footer {
    padding: .5rem;
    padding-left: .25rem;
    padding-top: .45rem;
    padding-bottom: .25rem;
    .list-unstyled {
      display: flex;
      gap: .5rem;
      margin-bottom: 0;
      .btn {
        color: #fff;
        font-weight: 400;
        border-radius: 8px;
        padding: .25rem;
      }
    }
    .btn-done {
      background-color: var(--sent-fg);
    }
    .btn-undone {
      background-color: var(--normal-fg);
    }
    .btn-edit {
      background-color: var(--rejected-fg);
    }
  }
  .collapse-preview a.collapsed:after {
    content: '+ Show More';
  }

  .collapse-preview a:not(.collapsed):after {
    content: '- Show Less';
  }

  .collapse-preview.collapse:not(.show) {
    display: block;
    overflow: hidden;
  }
  .backdrop-preview {
    display: none;
  }
  .collapse-preview.collapse:not(.show) ~ .backdrop-preview {
    position: relative;
    display: block;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      var(--background-2) 100%
    );
    height: 30px;
    margin-top: -30px;
  }

  .collapse-preview.collapsing, .collapse-preview.collapse:not(.show) {
    /* height = lineheight * no of lines to display */
    height: 1.8em;
  }
}
button {
  right: 5px;
  top: 3px;
  // padding: 0;
  height: fit-content;
  .delete-icon, .edit-icon {
    font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 48;
    color: var(--gray-font-color-3);
    margin-left: auto;
    font-size: 18px;
    transition: 0.5s;
    // position absolute style
    background-color: var(--background-2);

    &:hover,
    &:focus {
      opacity: 1;
      color: #d5273e;
      transform: scale(1.2);
    }
    &.edit-icon:hover,
    &.edit-icon:focus {
      opacity: 1;
      color: var(--normal-fg);
      transform: scale(1.2);
    }
  }
}
.card-item {
  padding: .5rem;
  padding-left: .25rem;
  padding-top: .45rem;
  padding-bottom: .25rem;
  &.card-item:first-of-type {
    padding-top: 1rem;
  }
  &.card-item:not(.card-item:first-of-type) {
    margin-top: 1rem;
  }

  > span, .label-sidebar-item {
    font-weight: 400;
  }
  input, .v-select, .p-calendar {
    margin-top: .5rem;
  }
}
.btn-wrapper {
  display: flex;
  padding: 1.5rem 1rem;
}
</style>
