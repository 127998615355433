<template>
  <div class="content">
    <!-- <div class="testing-container">
      <p>Workflow ao qual pertence: <span class="text-danger">Faltando</span></p>
      <p>Etapa da coluna: <span class="text-danger">Faltando</span></p>
      <p>Prioridade: <span v-if="currentTask.priority" :class="`badge badge-${currentTask.priority}`">● {{$t(`workflow.workspace.status.${currentTask.priority}`)}}</span></p>
      <p>Criado em: {{currentTask.created_at | displayDate}}</p>
      <p>Data de fechamento: {{currentTask.due_date | displayDate}}</p>
      <button class="btn btn-primary" @click="fetchedBoard = !fetchedBoard">Fetched: {{fetchedBoard}}</button>
    </div> -->
    <div class="page-content container-fluid settings">
      <div class="row">
        <div class="col col-sm">
          <PageHeader :breadcrumbLinks="breadcrumbLinks" title="Pipeline" :subtitle="breadcrumbInfo[0].value === '' ? breadcrumbInfo[0].text : breadcrumbInfo[0].value" :subtitle3rd="breadcrumbInfo[1].value === '' ? breadcrumbInfo[1].text : breadcrumbInfo[1].value" />
          <div class="container-fluid main-board">
            <div class="row">
              <!-- Main-content -->
              <div class="col">
                <!-- <code>{{$route.params.id}}</code> -->
                <!-- Deal infos -->
                <div class="container-fluid deal-details">
                  <div class="row">
                    <div class="col-8">
                      <div class="col deal-item value-column">
                        <!-- <p class="deal-title" :class="[!currentTask.title ? 'animated-background dummy-scale' : '']">{{currentTask.title}}</p> -->
                        <span class="label-sidebar-item" :class="[!currentTask.title ? 'animated-background dummy-scale' : '']">{{$tc('app.title', 1)}}</span>
                        <input class="form-control" :class="[!currentTask.title ? 'animated-background' : '']" v-model="currentTask.title" type="text">
                        <small :class="[!currentTask.title ? 'animated-background' : '']">Não exceda 25 caracteres</small>
                      </div>
                      <div class="col deal-item value-column">
                        <span class="label-sidebar-item" :class="[!currentTask.title ? 'animated-background' : '']">{{$t('app.description')}}</span>
                        <input class="form-control" :class="[!currentTask.title ? 'animated-background' : '']" v-model="currentTask.description" type="text">
                        <small :class="[!currentTask.title ? 'animated-background' : '']">Não exceda 200 caracteres ao inserir a descrição</small>
                      </div>
                      <div class="col deal-item value-column assign-user-column">
                        <div class="w-100 profile-wrapper">
                          <span class="label-sidebar-item" :class="[!currentTask.title ? 'animated-background' : '']">{{$t('workflow.workspace.card.assign')}}</span>
                          <!-- <template v-for="user in currentTask.users">
                            <div
                              class="profile"
                              :key="`carduser:${currentTask.id}:${user.id}`"
                              :title="`teamuser:${user.id}`"
                              :style="{ backgroundColor: $strToColor(user.name) }">
                                {{user.name | initials}}
                              </div>
                          </template> -->
                          <template v-if="currentTask.user">
                            <div v-if="seen" class="profile-around ml-2" :id="`popover-target-${currentTask.user.id}`">
                              <div
                                class="profile"
                                :key="`carduser:${currentTask.id}:${currentTask.user.id}`"
                                :style="{ backgroundColor: $strToColor(currentTask.user.name) }">
                                  {{currentTask.user.name | initials}}
                                </div>
                                <b-popover :target="`popover-target-${currentTask.user.id}`" triggers="hover" placement="top">
                                  <span>{{currentTask.user.name}}</span>
                                </b-popover>
                                <button @click="removeResponsible" class="btn btn-remove-responsible"><span class="material-symbols-outlined">close</span></button>
                            </div>
                            <!-- <button class="btn btn-add-responsible ml-2" @click="addResponsible = !addResponsible"><span class="material-symbols-outlined">{{!addResponsible ? 'add' : 'remove'}}</span></button> -->
                          </template>
                        </div>
                        <select-input
                          label="name"
                          class="w-100 mt-2"
                          :class="[!currentTask.title ? 'animated-background' : '']"
                          :reduce="(itemTask) => itemTask"
                          v-model="userQueryCardEdit"
                          :options="userAndDepCard"
                          :clearable="false"
                        />
                        <!-- @option:selected="insertUser()" -->
                      </div>
                      <div class="container-fluid deal-item">
                        <div class="row">
                          <!-- <div class="col-6 m-0 deal-item value-column">
                            <span class="label-sidebar-item">Espaço de trabalho</span>
                            <select-input
                              label="title"
                              class="w-100 mt-2"
                              :reduce="(itemStage) => itemStage"
                              v-model="selectedStage"
                              :options="boardOptions"
                              :clearable="false"
                            />
                          </div> -->
                          <div class="col m-0 deal-item value-column">
                            <span class="label-sidebar-item" :class="[!currentTask.title ? 'animated-background' : '']">Status</span>
                            <select-input
                              class="w-100"
                              :class="[!currentTask.priority ? 'animated-background' : '']"
                              label="text"
                              v-model="currentTask.priority"
                              :reduce="(cardPriorityOptions) => cardPriorityOptions.value"
                              :options="cardPriorityOptions"
                              :clearable="false"
                              :editable="false"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="container-fluid deal-item">
                        <div class="row">
                          <div class="col-6 m-0 deal-item value-column">
                            <span class="label-sidebar-item" :class="[!currentTask.title ? 'animated-background' : '']">Etapa</span>
                            <select-input
                              label="title"
                              class="w-100"
                              :class="[!currentTask.stage ? 'animated-background' : '']"
                              :reduce="(itemStage) => itemStage"
                              v-model="selectedStage"
                              :options="boardOptions"
                              :clearable="false"
                            />
                          </div>
                          <div class="col-6 m-0 deal-item value-column">
                            <span class="label-sidebar-item" :class="[!currentTask.title ? 'animated-background' : '']">Data de vencimento</span>
                            <Calendar id="icon" :class="[!currentTask.due_date ? 'animated-background' : '']" ref="calendarDate" v-model="currentTask.due_date" :value="this.currentTask.due_date" dateFormat="dd/mm/yy" :showTime="false" :showIcon="true" />
                          </div>
                          <div class="col d-flex mt-4">
                            <button class="btn btn-primary ml-auto" @click="handleEditTask">{{$t('app.save')}}</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--/Deal infos -->
                <div class="container-fluid">
                  <ul class="nav nav-tabs tabs-detail">
                    <!-- <li class="nav-item" role="presentation">
                      <button class="btn btn-nav-task active" id="v-pills-task-tab" data-toggle="pill" data-target="#v-pills-task" type="button" role="tab" aria-controls="v-pills-task" aria-selected="true"><span class="material-symbols-outlined">task</span> {{$tc('app.task', 2)}}</button>
                    </li> -->
                    <li class="nav-item" role="presentation">
                      <button class="btn btn-nav-task active" id="v-pills-comments-tab" data-toggle="pill" data-target="#v-pills-comments" type="button" role="tab" aria-controls="v-pills-comments" aria-selected="true"><span class="material-symbols-outlined">comment</span> {{$tc('app.comment', 2)}}</button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button class="btn btn-nav-task" id="v-pills-products-tab" data-toggle="pill" data-target="#v-pills-products" type="button" role="tab" aria-controls="v-pills-products" aria-selected="false"><span class="material-symbols-outlined">sell</span> Produtos</button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button class="btn btn-nav-task" id="v-pills-logs-tab" data-toggle="pill" data-target="#v-pills-logs" type="button" role="tab" aria-controls="v-pills-logs" aria-selected="false"><span class="material-symbols-outlined">history</span> Histórico</button>
                    </li>
                  </ul>
                  <div class="row task-content">
                    <div class="tab-content">
                      <!-- Task component -->
                      <!-- <div class="tab-pane fade show active" id="v-pills-task" role="tabpanel" aria-labelledby="v-pills-task-tab">
                        <div class="container-fluid">
                          <template v-if="currentTask.user">
                            <task-detail :users="[currentTask.user]" />
                          </template>
                        </div>
                      </div> -->
                      <!--/Task component -->
                      <!-- Comment component -->
                      <div class="tab-pane fade show active" id="v-pills-comments" role="tabpanel" aria-labelledby="v-pills-comments-tab">
                        <div class="container-fluid">
                          <div class="row comments-row">
                            <div class="col">
                              <!-- <textarea class="form-control" name="new-comment" id="create-comment" cols="20" rows="2"></textarea> -->
                              <ol class="timeline">
                                <comments-detail :user="currentTask" />
                              </ol>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!--/Comment component -->
                      <!-- Task component -->
                      <div class="tab-pane fade" id="v-pills-products" role="tabpanel" aria-labelledby="v-pills-products-tab">
                        <div class="container-fluid">
                          <template v-if="fetchedBoard">
                            <product-detail :users="[currentTask.user]" :products="currentTask.offerings" :currentTask="currentTask" />
                          </template>
                        </div>
                      </div>
                      <!--/Task component -->
                      <!-- log component -->
                      <div class="tab-pane fade" id="v-pills-logs" role="tabpanel" aria-labelledby="v-pills-logs-tab">
                        <div class="container-fluid">
                          <template>
                            <LogList :dealId="currentTask.id" />
                          </template>
                        </div>
                      </div>
                      <!--/log component -->
                    </div>
                  </div>
                  <div class="row task-content" v-if="false">
                    <div class="col-2">
                      <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                        <button class="btn btn-nav-task active" id="v-pills-task-tab" data-toggle="pill" data-target="#v-pills-task" type="button" role="tab" aria-controls="v-pills-task" aria-selected="true"><span class="material-symbols-outlined">task</span> {{$tc('app.task', 2)}}</button>
                        <button class="btn btn-nav-task" id="v-pills-comments-tab" data-toggle="pill" data-target="#v-pills-comments" type="button" role="tab" aria-controls="v-pills-comments" aria-selected="false"><span class="material-symbols-outlined">comment</span> {{$tc('app.comment', 2)}}</button>
                      </div>
                    </div>
                    <div class="col">
                      <div class="tab-content" id="v-pills-tabContent">
                        <div class="tab-pane fade show active" id="v-pills-task" role="tabpanel" aria-labelledby="v-pills-task-tab">
                          <div class="row show-tasks">
                            <!-- Task 1 -->
                            <div class="card card-task">
                              <div class="card-header">
                                <div class="task-title">Tarefa título</div>
                                <div class="options-task">
                                  <ul class="list-unstyled">
                                    <li><button class="btn"><span class="material-symbols-outlined done">done</span></button></li>
                                  </ul>
                                </div>
                              </div>
                              <div class="card-body">
                                <div id="collapse-desc-1" class="w-100 collapse collapse-preview">
                                  <div class="task-description">
                                    Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dolore, rerum optio alias nisi error voluptate nobis veritatis similique veniam eaque iusto quam recusandae quia voluptatem, neque provident delectus a facilis.
                                  </div>
                                </div>
                                <div class="backdrop-preview"></div>
                                <button class="btn" data-toggle="collapse" data-target="#collapse-desc-1" aria-expanded="false" aria-controls="collapse-desc-1">Mostrar mais</button>
                              </div>
                            </div>
                            <!--/Task 1 -->
                            <!-- Task 2 -->
                            <div class="card card-task">
                              <div class="card-header">
                                <div class="task-title">Tarefa título</div>
                                <div class="options-task">
                                  <ul class="list-unstyled">
                                    <li><button class="btn"><span class="material-symbols-outlined not-done">close</span></button></li>
                                  </ul>
                                </div>
                              </div>
                              <div class="card-body">
                                <div id="collapse-desc-2" class="w-100 collapse collapse-preview">
                                  <div class="task-description">
                                    Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dolore, rerum optio alias nisi error voluptate nobis veritatis similique veniam eaque iusto quam recusandae quia voluptatem, neque provident delectus a facilis.
                                  </div>
                                </div>
                                <div class="backdrop-preview"></div>
                                <button class="btn" data-toggle="collapse" data-target="#collapse-desc-2" aria-expanded="false" aria-controls="collapse-desc-2">Mostrar mais</button>
                              </div>
                            </div>
                            <!--/Task 2 -->
                            <!-- Task 3 -->
                            <div class="card card-task">
                              <div class="card-header">
                                <div class="task-title">Tarefa título</div>
                                <div class="options-task">
                                  <ul class="list-unstyled">
                                    <li><button class="btn"><span class="material-symbols-outlined done">done</span></button></li>
                                  </ul>
                                </div>
                              </div>
                              <div class="card-body">
                                <div id="collapse-desc-3" class="w-100 collapse collapse-preview">
                                  <div class="task-description">
                                    Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dolore, rerum optio alias nisi error voluptate nobis veritatis similique veniam eaque iusto quam recusandae quia voluptatem, neque provident delectus a facilis.
                                  </div>
                                </div>
                                <div class="backdrop-preview"></div>
                                <button class="btn" data-toggle="collapse" data-target="#collapse-desc-3" aria-expanded="false" aria-controls="collapse-desc-3">Mostrar mais</button>
                              </div>
                            </div>
                            <!--/Task 3 -->
                          </div>
                        </div>
                        <div class="tab-pane fade" id="v-pills-comments" role="tabpanel" aria-labelledby="v-pills-comments-tab">
                          <div class="row comments-row">
                            <div class="col">
                              <!-- <textarea class="form-control" name="new-comment" id="create-comment" cols="20" rows="2"></textarea> -->
                              <ol class="timeline">
                                <li class="timeline-item">
                                  <span class="timeline-item-icon | avatar-icon">
                                    <i class="avatar">
                                      <img src="https://assets.codepen.io/285131/hat-man.png" />
                                    </i>
                                  </span>
                                  <div class="new-comment">
                                    <!-- <input type="text" placeholder="Add a comment..." /> -->
                                    <textarea class="form-control" placeholder="Add a comment..." name="new-comment" id="create-comment" cols="20" rows="2"></textarea>
                                  </div>
                                </li>
                                <li class="timeline-item">
                                  <span class="timeline-item-icon | faded-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                      <path fill="none" d="M0 0h24v24H0z" />
                                      <path fill="currentColor" d="M12.9 6.858l4.242 4.243L7.242 21H3v-4.243l9.9-9.9zm1.414-1.414l2.121-2.122a1 1 0 0 1 1.414 0l2.829 2.829a1 1 0 0 1 0 1.414l-2.122 2.121-4.242-4.242z" />
                                    </svg>
                                  </span>
                                  <div class="timeline-item-description">
                                    <i class="avatar | small">
                                      <img src="https://assets.codepen.io/285131/winking-girl.png" />
                                    </i>
                                    <span><a href="#">Luna Bonifacio</a> has changed <a href="#">2 attributes</a> on <time datetime="21-01-2021">Jan 21, 2021</time></span>
                                  </div>
                                </li>
                                <li class="timeline-item">
                                  <span class="timeline-item-icon | faded-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                      <path fill="none" d="M0 0h24v24H0z" />
                                      <path fill="currentColor" d="M12 13H4v-2h8V4l8 8-8 8z" />
                                    </svg>
                                  </span>
                                  <div class="timeline-item-description">
                                    <i class="avatar | small">
                                      <img src="https://assets.codepen.io/285131/hat-man.png" />
                                    </i>
                                    <span><a href="#">Yoan Almedia</a> moved <a href="#">Eric Lubin</a> to <a href="#">📚 Technical Test</a> on <time datetime="20-01-2021">Jan 20, 2021</time></span>
                                  </div>
                                </li>
                                <li class="timeline-item | extra-space">
                                  <span class="timeline-item-icon | filled-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                      <path fill="none" d="M0 0h24v24H0z" />
                                      <path fill="currentColor" d="M6.455 19L2 22.5V4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H6.455zM7 10v2h2v-2H7zm4 0v2h2v-2h-2zm4 0v2h2v-2h-2z" />
                                    </svg>
                                  </span>
                                  <div class="timeline-item-wrapper">
                                    <div class="timeline-item-description">
                                      <i class="avatar | small">
                                        <img src="https://assets.codepen.io/285131/hat-man.png" />
                                      </i>
                                      <span><a href="#">Yoan Almedia</a> commented on <time datetime="20-01-2021">Jan 20, 2021</time></span>
                                    </div>
                                    <div class="comment">
                                      <p>I've sent him the assignment we discussed recently, he is coming back to us this week. Regarding to our last call, I really enjoyed talking to him and so far he has the profile we are looking for. Can't wait to see his technical test, I'll keep you posted and we'll debrief it all together!😊</p>
                                      <button class="button">👏 2</button>
                                      <button class="button | square">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                          <path fill="none" d="M0 0h24v24H0z" />
                                          <path fill="currentColor" d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zM7 12a5 5 0 0 0 10 0h-2a3 3 0 0 1-6 0H7z" />
                                        </svg>
                                      </button>
                                    </div>
                                    <button class="show-replies">
                                      <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-arrow-forward" width="44" height="44" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M15 11l4 4l-4 4m4 -4h-11a4 4 0 0 1 0 -8h1" />
                                      </svg>
                                      Show 3 replies
                                      <span class="avatar-list">
                                        <i class="avatar | small">
                                          <img src="https://assets.codepen.io/285131/hat-man.png" />
                                        </i>
                                        <i class="avatar | small">
                                          <img src="https://assets.codepen.io/285131/winking-girl.png" />
                                        </i> <i class="avatar | small">
                                          <img src="https://assets.codepen.io/285131/smiling-girl.png" />
                                        </i>
                                      </span>
                                    </button>
                                  </div>
                                </li>
                              </ol>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--/Main-content -->
              <!-- <div class="loading w-100 min-h-300" v-show="false">
                <LoadingAnim />
              </div> -->
            </div>
          </div>
        </div>
        <!-- Side column -->
        <div class="col-3 side-column">
          <div class="loading w-100 all-infos" v-show="!fetchedInfos">
            <LoadingAnim />
          </div>
          <div v-show="fetchedInfos">
            <template v-if="!this.currentTask.result || this.currentTask.result === 'resume'">
              <div class="concluded-wrapper">
                <div class="loading w-100 concluded-loading" v-show="loadingConclusion">
                  <LoadingAnim />
                </div>
                <!-- <p class="mb-0"><b>Marcar como: </b></p> -->
                <div class="icon-wrapper" v-show="!loadingConclusion">
                  <button @click="handleConclusion('done')" class="btn icon-action done" :class="[this.currentTask.result === 'done' ? 'active' : '']"><span class="material-symbols-outlined fill-icon">thumb_up</span> {{$t('workflow.workspace.card.concluded.done.title')}}</button>
                  <button @click="handleConclusion('canceled')" class="btn icon-action not-done" :class="[this.currentTask.result === 'canceled' ? 'active' : '']"><span class="material-symbols-outlined fill-icon">thumb_down</span> {{$t('workflow.workspace.card.concluded.not-done.title')}}</button>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="concluded-wrapper">
                <!-- <h3>Status:</h3> -->
                <div class="loading w-100 concluded-loading" v-show="loadingConclusion">
                  <LoadingAnim />
                </div>
                <div class="status-wrapper" v-if="currentTask.result !== 'done' || currentTask.result !== 'canceled'" v-show="!loadingConclusion">
                  <p class="done" v-if="this.currentTask.result === 'done'"><span class="material-symbols-outlined fill-icon">thumb_up</span>{{$t('workflow.workspace.card.concluded.done.status')}}</p>
                  <p class="lost mb-0" v-else-if="this.currentTask.result === 'canceled'"><span class="material-symbols-outlined fill-icon">thumb_down</span>{{$t('workflow.workspace.card.concluded.not-done.status')}}</p>
                  <button class="btn btn-outline-primary bg-transparent border-0" @click="handleConclusion('resume')">Retomar negociação?</button>
                </div>
              </div>
            </template>
            <template v-if="fetchedBoard">
              <template>
                <task-detail :countTasks="currentTask.tasks.length" />
              </template>
              <h2>{{$t('properties.infos')}}</h2>
              <!-- <template v-if="currentTask.users.length > 0"> -->
                <div class="contact-info">
                  <ul class="tasks-options list-unstyled">
                    <li><span class="title">Informações de contato</span><!-- <button @click="addUserCard = !addUserCard" class="btn btn-outline-primary bg-transparent ml-auto border-0"><span class="material-symbols-outlined">add_alert</span>Cadastrar contato</button> --></li>
                  </ul>
                  <div class="contact-wrapper" v-if="!currentTask.contact">
                    <span class="material-symbols-outlined person-icon">person</span>
                    <p class="mb-0">Nenhum contato vinculado</p>
                  </div>
                  <div class="form-wrapper" v-if="!currentTask.contact">
                    <select-input
                      label="name"
                      :reduce="(item) => item"
                      v-model="contactQuery"
                      @search="(search, loading) => {
                        if(search.length > 2) fetchContacts(search)
                      }"
                      @option:selected="insertToContactDeal()"
                      :options="contactsDeal"
                      :clearable="true">
                      <template #no-options="{}">
                        Nenhum resultado encontrado!
                      </template>
                    </select-input>
                    <div id="popover-target-contact"><button class="btn btn-primary" @click="attachContact()" :disabled="!contactQuery">Vincular contato</button></div>
                    <b-popover target="popover-target-contact" triggers="hover" placement="top">
                      <span v-if="!contactQuery">Selecione um contato!</span>
                    </b-popover>
                  </div>
                  <div class="contact-wrapper" v-if="contactExist">
                    <span class="material-symbols-outlined person-icon">person</span>
                    <p class="mb-0">{{currentTask.contact.last_name ? `${currentTask.contact.first_name} ${currentTask.contact.last_name}` : currentTask.contact.first_name}}</p>
                    <div class="channels">
                      <ul class="list-unstyled">
                        <li>
                          <div class="control-group opt-2">
                            <span v-tooltip.right="$t('contacts-component.send.message')" @click="goToSend('SmsSend')">
                              <img src="assets/img/icons/svg/SMS.svg" class="c-pointer"/>
                            </span>
                          </div>
                        </li>
                        <li>
                          <div class="control-group opt-2">
                            <span to="/whatsapp/send" v-tooltip.right="'Enviar WhatsApp'" @click="goToSend('WhatsAppSend')">
                              <img src="assets/img/icons/svg/Whatsapp.svg" class="c-pointer"/>
                            </span>
                          </div>
                        </li>
                        <li>
                          <div class="control-group opt-2">
                            <span to="/email/send" v-tooltip.right="'Enviar Email'" @click="goToSend('MailSend')">
                              <img src="assets/img/icons/svg/email.svg" class="c-pointer"/>
                            </span>
                          </div>
                        </li>
                        <li>
                          <div class="control-group opt-2">
                            <span to="/rcs/send" v-tooltip.right="'Enviar RCS'" @click="goToSend('RCSSend')">
                              <img src="assets/img/icons/svg/RCS.svg" class="c-pointer"/>
                            </span>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <ul class="contact-list-items">
                      <li v-if="currentTask.contact.company">
                        <div class="icn">
                          <span class="material-icons-outlined">business</span>
                        </div>
                        <div class="item">
                          {{currentTask.contact.company ? currentTask.contact.company : $t('contact-list-component.user.job-not-found')}}
                        </div>
                      </li>
                      <li v-if="currentTask.contact.gender">
                        <div class="icn">
                          <span class="material-icons-outlined">transgender</span>
                        </div>
                        <div class="item">
                        {{currentTask.contact.gender ? currentTask.contact.gender : $t('contact-list-component.user.gen-not-found')}}
                        </div>
                      </li>
                      <li v-if="currentTask.contact.birthdate | formatDate">
                        <div class="icn">
                          <span class="material-icons-outlined">calendar_today</span>
                        </div>
                        <div class="item">
                        {{currentTask.contact.birthdate | formatDate}}
                        </div>
                      </li>
                      <li v-if="currentTask.contact.website">
                        <div class="icn">
                          <span class="material-icons-outlined">language</span>
                        </div>
                        <div class="item">
                        {{currentTask.contact.website ? currentTask.contact.website : $t('contact-list-component.user.website-not-found')}}
                        </div>
                      </li>
                      <li v-if="currentTask.contact.email">
                        <div class="icn">
                          <span class="material-icons-outlined">email</span>
                        </div>
                        <div class="item">
                        {{currentTask.contact.email ? currentTask.contact.email : $t('contact-list-component.user.email-not-found')}}
                        </div>
                      </li>
                      <li v-if="currentTask.contact.mobile_number">
                        <div class="icn">
                          <span class="material-icons-outlined">phone</span>
                        </div>
                        <div class="item" v-if="currentTask.contact.mobile_number">
                          {{currentTask.contact.mobile_number | maskPhone}}
                        </div>
                        <div class="item" v-else>
                          {{$t('contact-list-component.user.number-not-found')}}
                        </div>
                      </li>
                      <li v-if="currentTask.contact.phone">
                        <div class="icn">
                          <span class="material-icons-outlined">phone</span>
                        </div>
                        <div class="item" v-if="currentTask.contact.phone">
                          {{currentTask.contact.phone | maskPhone}}
                        </div>
                        <div class="item" v-else>
                          {{$t('contact-list-component.user.number-not-found')}}
                        </div>
                      </li>
                      <li v-if="currentTask.contact.facebook">
                        <div class="icn">
                          <figure>
                            <img src="@/assets/icons/social/facebook.png" />
                          </figure>
                        </div>
                        <div class="item">
                          <a :href="currentTask.contact.facebook" target="_blank">@{{matchedString.facebook.replace(/[/]/g, '')}}</a>
                        </div>
                      </li>
                      <li v-if="currentTask.contact.instagram">
                        <div class="icn">
                          <figure>
                            <img src="@/assets/icons/social/instagram.png" />
                          </figure>
                        </div>
                        <div class="item">
                          <a :href="currentTask.contact.instagram" target="_blank">@{{matchedString.instagram.replace(/[/]/g, '')}}</a>
                        </div>
                      </li>
                      <li v-if="currentTask.contact.twitter">
                        <div class="icn">
                          <figure>
                            <img src="@/assets/icons/social/twitter.png" />
                          </figure>
                        </div>
                        <div class="item">
                        <a :href="currentTask.contact.twitter" target="_blank">@{{matchedString.twitter.replace(/[/]/g, '')}}</a>
                        </div>
                      </li>
                      <li v-if="currentTask.contact.linkedin">
                        <div class="icn">
                          <figure>
                            <img src="@/assets/icons/social/linkedin.png" />
                          </figure>
                        </div>
                        <div class="item">
                          <a :href="currentTask.contact.linkedin" target="_blank">@{{matchedString.linkedin.replace(/[/in/]/g, '')}}</a>
                        </div>
                      </li>
                      <li v-if="currentTask.contact.address">
                        <div class="icn">
                          <span class="material-icons-outlined">location_on</span>
                        </div>
                        <div class="item" v-if="currentTask.contact.address">
                          {{currentTask.contact.address ? currentTask.contact.address : ''}}{{currentTask.contact.number ? `, nº ${currentTask.contact.number}` : ''}}{{currentTask.contact.complement ? `, ${currentTask.contact.complement}` : ''}}{{currentTask.contact.district ? `, ${currentTask.contact.district}` : ''}}{{currentTask.contact.zip ? `. ${$t('generic-str.zipcode')}: ${currentTask.contact.zip}` : ''}}{{currentTask.contact.state ? `, ${currentTask.contact.state}` : ''}}
                        </div>
                      </li>
                    </ul>
                    <button class="btn btn-outline-danger bg-transparent ml-auto border-0 d-flex align-items-center pl-2 pr-1" @click="detachContact()">Remover vínculo<span class="material-symbols-outlined">delete_forever</span></button>
                  </div>
                </div>
                <div class="user-wrapper" v-show="false">
                  <span role="button" class="toggle-custom" @click="toggleBs('collapse-1'), customToggle = !customToggle">{{$tc('workflow.workspace.board.responsible', 1, {length: 1})}}<span class="material-symbols-outlined" :class="{rotate: customToggle}">expand_more</span></span>
                  <b-collapse id="collapse-1" class="mt-2 w-100" visible>
                    <div class="px-1 py-2">
                      <!-- <template v-if="currentTask.users.length > 1">
                        <p class="user-item" >
                          <span class="material-symbols-outlined">person</span>
                          <span v-for="(userItem, index) in currentTask.users" :key="index">{{(index + 1) !== currentTask.users.length ? `${userItem.name}, ` : userItem.name}}</span>
                        </p>
                      </template>
                      <template v-else>
                        <p class="user-item"><span class="material-symbols-outlined">person</span>{{currentTask.users[0].name}}</p>
                      </template>
                      -->
                      <p class="user-item" v-if="contactExist"><span class="material-symbols-outlined">person</span>{{currentTask.user ? currentTask.user.name : `${currentTask.contact.first_name} ${currentTask.contact.last_name ? currentTask.contact.last_name : ''}`}}</p>
                      <template v-if="contactExist">
                        <p class="user-item"><span class="material-symbols-outlined">work</span>{{$t('app.company')}}: {{currentTask.contact.company}}</p>
                        <p class="user-item"><span class="material-symbols-outlined">alternate_email</span>{{$t('app.email')}}: {{currentTask.contact.email}}</p>
                        <p class="user-item"><span class="material-symbols-outlined">call</span>{{$t('app.mobile_number')}}: {{currentTask.contact.mobile_number | maskPhone}}</p>
                      </template>
                      <p class="user-item"><span class="material-symbols-outlined">priority_high</span>{{$t('generic-str.priority')}}: <span v-if="currentTask.priority" :class="`badge badge-${currentTask.priority}`">● {{$t(`workflow.workspace.status.${currentTask.priority}`)}}</span></p>
                      <p class="user-item"><span class="material-symbols-outlined">today</span>{{$t('sms.templates.lbl-created')}}: {{currentTask.created_at | displayDate(months, today, $i18n.locale)}}</p>
                      <p class="user-item" v-if="currentTask.due_date"><span class="material-symbols-outlined">event</span>{{$t('workflow.workspace.card.closing-date')}}: {{currentTask.due_date | displayDate(months, today, $i18n.locale)}}</p>
                      <template v-if="!this.currentTask.result || this.currentTask.result === 'resume'">
                        <div class="concluded-wrapper">
                          <p class="mb-0"><b>Marcar como: </b></p>
                          <div class="icon-wrapper">
                            <button @click="handleConclusion('canceled')" class="btn icon-action not-done" :class="[this.currentTask.result === 'canceled' ? 'active' : '']"><span class="material-symbols-outlined fill-icon">thumb_down</span> {{$t('workflow.workspace.card.concluded.not-done.title')}}</button>
                            <button @click="handleConclusion('done')" class="btn icon-action done" :class="[this.currentTask.result === 'done' ? 'active' : '']"><span class="material-symbols-outlined fill-icon">thumb_up</span> {{$t('workflow.workspace.card.concluded.done.title')}}</button>
                          </div>
                        </div>
                      </template>
                      <template v-else>
                        <div class="concluded-wrapper">
                          <h3>Status:</h3>
                          <div class="status-wrapper">
                            <p class="done" v-if="this.currentTask.result === 'done'"><span class="material-symbols-outlined fill-icon">thumb_up</span>{{$t('workflow.workspace.card.concluded.done.status')}}</p>
                            <p class="lost" v-else-if="this.currentTask.result === 'canceled'"><span class="material-symbols-outlined fill-icon">thumb_down</span>{{$t('workflow.workspace.card.concluded.not-done.status')}}</p>
                            <button class="btn btn-outline-primary" @click="handleConclusion('resume')">Retomar negociação?</button>
                          </div>
                        </div>
                      </template>
                      <!-- <div class="py-2">
                        <b-button block @click="log(currentTask.stage.id)">
                          {{currentTask.stage.title}}
                        </b-button>
                        <b-button block @click="log(currentTask.stage.pipeline.id)">
                          {{currentTask.stage.pipeline.title}}
                        </b-button>
                      </div> -->
                    </div>
                  </b-collapse>
                </div>
              <!-- </template> -->
            </template>
          </div>
        </div>
        <!--/Side column -->
      </div>
    </div>
  </div>
</template>

<script>
import 'primevue/resources/themes/saga-blue/theme.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'primevue/resources/primevue.min.css';
// import 'primeicons/primeicons.css';
import $ from 'jquery';
import bootstrap from 'bootstrap'; // Collapse horizontal
// @ is an alias to /src
import axios from '@/plugins/axios';
import PageHeader from '@/components/rebranding/PageHeader.vue';
import TaskDetail from '@/components/rebranding/workflow/detail/Tasks.vue';
import CommentsDetail from '@/components/rebranding/workflow/detail/Comments.vue';
import ProductDetail from '@/components/rebranding/workflow/detail/Products.vue';
import LogList from '@/components/rebranding/logs-report/LogList.vue';
import Swal from 'sweetalert2';
import moment from 'moment';
import SelectInput from '@/components/SelectInput.vue';
import LoadingAnim from '@/components/rebranding/animLottie/Loading.vue';
import Calendar from 'primevue/calendar';
import Vue from 'vue';
import { cloneDeep } from 'lodash';
import { clearTimeout } from '../../../../../public/assets/vendor/lodash/_freeGlobal';
// import Pagination from '@/components/rebranding/Pagination.vue';
// import HiddenInput from '@/components/HiddenInput.vue';
// import WhatsService from '@/services/whatsapp.service';
// import Darkmode from '@/components/rebranding/SetTheme.vue';

const webApi = axios();

export default {
  name: 'BoardDetail',
  components: {
    PageHeader,
    LoadingAnim,
    TaskDetail,
    CommentsDetail,
    ProductDetail,
    SelectInput,
    Calendar,
    LogList,
    // Pagination
    // HiddenInput,
    // Darkmode,
  },
  /* props: {
    boardId: {
      type: String,
    },
  }, */
  filters: {
    initials(name) {
      let [f, l] = name.replace(/\s(do|de|da)\s/gi, ' ').split(/\s+/, 2);

      f = `${f || ' '}`.toUpperCase();
      l = `${l || ' '}`.toUpperCase();

      return `${f[0]}${l[0]}`;
    },
    displayDate(date, monthNames, localeToday, locale) {
      const today = new Date();
      const current = new Date(date);

      if (today.toDateString() === current.toDateString()) {
        return localeToday;
      }

      const months = monthNames;
      const y = current.getFullYear();
      const m = current.getMonth();
      const d = `${current.getDate()}`.padStart(2, 0);

      return (locale === 'en' ? `${months[m]} ${d}, ${y}` : `${d} ${months[m]}, ${y}`);
    },
    maskPhone(phone) {
      if (!phone) return '';

      phone = phone.replace(/\D+/g, '').substring(0, 13);

      switch (phone.length) {
        case 13:
          phone = phone.replace(/^(\d{2})(\d{2})(\d{5})(\d{4})$/, '+$1 ($2) $3-$4');
          break;
        case 12:
          phone = phone.replace(/^(\d{2})(\d{2})(\d{4})(\d{4})$/, '+$1 ($2) $3-$4');
          break;
        case 11:
          phone = phone.replace(/^(\d{2})(\d{2})(\d{5})(\d{4})$/, '+55 ($2) $3-$4');
          break;
        case 10:
          phone = phone.replace(/^(\d{2})(\d{2})(\d{4})(\d{4})$/, '+55 ($2) $3-$4');
          break;
        default:
      }
      return phone;
    },
  },
  data() {
    return {
      breadcrumbLinks: {
        1: '/pipelines',
        2: '',
        3: '',
      },
      breadcrumbInfo: [
        {
          text: '',
          value: '',
        },
        {
          text: '',
          value: '',
        },
      ],
      currentBoard: {},
      currentTask: {
        id: '',
        account_id: '',
        created_at: '',
        updated_at: '',
        title: '',
        description: '',
        status: '',
        deleted_at: null,
        stages: [
          {
            id: '',
            account_id: '',
            created_at: '',
            updated_at: '',
            title: '',
            pipeline_id: '',
            position: 0,
            deleted_at: null,
            /* deals: [
              {
                id: '',
                account_id: '',
                user_id: null,
                created_at: '',
                updated_at: '',
                probability: null,
                due_date: '',
                title: '',
                description: '',
                priority: '',
                amount: null,
                position: 0,
                stage_id: '',
                deleted_at: null,
                result: '',
                result_at: '',
                contact_id: null,
                comments_count: 0,
                user: null,
                contact: null,
                offerings: [
                  {
                    id: '733fe035-4a5a-4290-a8a2-fd5e5d2b3bb3',
                    account_id: '29af08dd-1c1e-46e8-b19d-29ba400fed40',
                    created_at: '2023-12-08T20:01:33.000000Z',
                    updated_at: '2023-12-08T20:01:33.000000Z',
                    title: 'Programa Java para gest\u00e3o de mini-mercados',
                    description:
                      'Programa Java para gest\u00e3o de mini-mercados',
                    amount: '45000.00',
                    deleted_at: null,
                    pivot: {
                      deal_id: '312eb684-6a30-4870-8ccb-1eabec4110f1',
                      offering_id: '733fe035-4a5a-4290-a8a2-fd5e5d2b3bb3',
                      id: 'efdc1897-285a-438d-93a6-1f3bc9f278e4',
                      recurrence: 'single',
                      quantity: 1,
                      discount: '0.00',
                    },
                  },
                ],
              },
            ], */
          },
          {
            id: '',
            account_id: '',
            created_at: '',
            updated_at: '',
            title: '',
            pipeline_id: '',
            // position: 1,
            // deleted_at: null,
            // deals: [],
          },
        ],
      },
      fetched: false,
      fetchedBoard: false,
      fetchedInfos: false,
      loadingConclusion: true,
      contactExist: false,
      seen: false,
      addResponsible: false,
      isSending: false,
      isSendingTask: false,
      searchQuery: null,
      addUserQuery: null,
      addUserCard: false,
      formatDate: null,
      matchedString: [
        {
          facebook: null,
          instagram: null,
          twitter: null,
          linkedin: null,
        },
      ],
      url: null,
      columnName: '',
      leadName: '',
      leadDescription: '',
      taskComment: '',
      taskStatus: '',
      progressValue: 60,
      uploadPercentage: 0,
      cardDate: '',
      cardChannels: [],
      cardChannelsOptions: [
        { text: 'E-mail', value: 'email' },
        { text: 'RCS', value: 'rcs' },
        { text: 'SMS', value: 'sms' },
        { text: 'WhatsApp', value: 'whatsapp' },
      ],
      cardValue: '',
      cardDescription: '',
      cardPriority: 'normal',
      cardPriorityOptions: [{ text: 'Baixo', value: 'low' }, { text: 'Normal', value: 'medium' }, { text: 'Alto', value: 'high' }, { text: 'Urgente', value: 'urgent' }],
      taskDate: null,
      taskValue: null,
      selectedStatus: '',
      selectedColumn: null,
      columnIndex: null,
      columnTitle: null,
      customToggle: true,
      showTooltip: false,
      showTooltip2: false,
      selectedDate: '',
      task:
        {
          title: this.$tc('app.title', 1),
          edit: false,
        },
      // pages: 1,
      // Test purpose
      // randomNumber: Number,
      viewType: 'grid',
      editingTitle: false,
      statusType: ['Ativo', 'Finalizado', 'Pausado', 'Arquivado'],
      boardDate: '',
      isAddingColumn: false,
      showAddUser: false,
      price: null,
      timeout: null,
      innerTime: null,
      addingUser: false,
      contactsDeal: [],
      selectedContacts: [],
      userAndDepCard: [],
      userAndDepCardEdit: [],
      selectedStage: [],
      boardOptions: [],
      contactQuery: '',
      userQuery: '',
      userQueryCard: '',
      userQueryCardEdit: {
        id: '',
        name: '',
      },
      manageContactList: [],
      manageTeamList: [],
      manageTeamListCard: [],
      savingTeam: false,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: '',
        precision: 2,
        masked: false,
      },
      taskTitle: '',
      months: this.$t('primevue.monthNames'),
      today: this.$t('primevue.today'),
      offerings: [],
    };
  },
  created() {
    this.fetch();
  },
  mounted() {
    this.fetchUsersDepBoard();
  },
  methods: {
    isSaving() {
      return new Promise((resolve, reject) => {
        // const offering = { ...this.offering };

        // offering.attach = true;

        this.isSending = true;
        console.log({ 'this.userQueryCardEdit': this.userQueryCardEdit });
        // const date = moment(this.currentTask.due_date).format();
        // const date2 = new Date('2023-11-15 18:53:40');
        // console.log('Date: ', this.currentTask.due_date, 'ISO date: ', date);
        // console.log('New price: ', price, 'Old price: ', this.currentTask.value);
        // console.log('>>> Date', moment(this.currentTask.due_date, 'DD/MM/YYYY').toISOString());
        // const date = new Date(this.currentTask.due_date).toISOString();
        const date = moment(this.currentTask.due_date, 'DD/MM/YYYY').toISOString();
        let price = '0000';
        if (this.currentTask.amount) {
          price = this.currentTask.amount.replace(/[R$ ]/g, '').replace(/[,]/g, '').replace(/[.]/g, '');
        }
        if (!this.currentTask.title) {
          this.$toast.show({
            title: `${this.$t('sms.infos.filters.status.lbl-error')}`,
            content: 'Insira um título!',
            type: 'danger',
          });
        } else if (!this.currentTask.description) {
          this.$toast.show({
            title: `${this.$t('sms.infos.filters.status.lbl-error')}`,
            content: 'Insira uma descrição!',
            type: 'danger',
          });
        } else if (!this.currentTask.priority) {
          this.$toast.show({
            title: `${this.$t('sms.infos.filters.status.lbl-error')}`,
            content: 'Campo prioridade é obrigatório!',
            type: 'danger',
          });
        } else if (!date) {
          this.$toast.show({
            title: `${this.$t('sms.infos.filters.status.lbl-error')}`,
            content: 'Campo data é obrigatório!',
            type: 'danger',
          });
        } else {
          webApi.put(`/pipelines/deals/${this.currentTask.id}`, {
            title: this.currentTask.title,
            description: this.currentTask.description,
            priority: this.currentTask.priority,
            // value: price,
            due_date: date,
            assign_to: this.userQueryCardEdit?.id,
            stage: this.selectedStage.id,
            // offerings: [offering],
          }).then(
            (response) => {
              console.log('Edit task response: ', response);
              this.$toast.show({
                title: this.$t('generic-str.success'),
                content: this.$t('workflow.workspace.card.edit.success'),
                type: 'success',
              });
              this.isSending = false;
              this.leadName = '';
              this.leadDescription = '';
              // this.toggleSidebar('sidebar-right-show-task');
              return resolve(response);
              // this.fetch();
            },
            (error) => {
              this.isSending = false;
              this.fetched = true;
              console.error({ error });
              this.content = error;
              this.$toast.show({
                title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
                content: error.message,
                type: 'danger',
              });
              return reject(error);
            },
          ).finally(() => {
            this.isSending = false;
          });
        }
      });
    },
    handleEditTask() {
      // this.isSending = true;
      let myPromise = Promise;
      if (!this.currentTask.priority) {
        this.$toast.show({
          title: `${this.$t('sms.infos.filters.status.lbl-error')}`,
          content: 'Campo prioridade está com valor incorreto',
          type: 'danger',
        });
        this.isSending = false;
      } else if (this.taskComment) {
        myPromise = Promise.all([this.isComment(), this.isSaving()]);
        this.taskComment = '';
        // this.currentTask = {};
        setTimeout(() => {
          // this.stages = [];
          this.fetch();
        }, 300);
      } else {
        myPromise = Promise.all([this.isSaving()]);
        // this.currentTask = {};
        setTimeout(() => {
          // this.stages = [];
          this.fetch();
        }, 300);
      }
    },
    goToSend(name) {
      this.$router.push({
        name,
        params: { contacts: this.selectedContacts },
      });
    },
    attachContact() {
      Swal.fire({
        title: 'Vincular contato?',
        // text: this.$t('workflow.workspace.task.swal.sure'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('generic-str.yes'),
        cancelButtonText: this.$t('generic-str.no'),
      }).then((result) => {
        if (result.value) {
          if (!this.contactQuery) {
            this.$toast.show({
              title: `${this.$t('sms.infos.filters.status.lbl-error')}`,
              content: 'Contato não selecionado!',
              type: 'danger',
            });
          } else {
            this.fetched = false;
            webApi.put(`/pipelines/deals/${this.$route.params.id}`, {
              contact_id: this.contactQuery.id,
            }).then(
              (response) => {
                console.log('Contact add response: ', response);
                this.$toast.show({
                  title: this.$t('generic-str.success'),
                  content: 'Contato vinculado com sucesso!',
                  type: 'success',
                });
              },
              (error) => {
                this.fetched = true;
                console.error({ error });
                this.content = error;
                this.$toast.show({
                  title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
                  content: error.message,
                  type: 'danger',
                });
              },
            ).finally(() => {
              // this.fetched = true;
              this.contactQuery = '';
              this.contactExist = true;
              this.fetch();
            });
          }
        }
      });
    },
    detachContact() {
      Swal.fire({
        title: 'Desvincular contato?',
        // text: this.$t('workflow.workspace.task.swal.sure'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('generic-str.yes'),
        cancelButtonText: this.$t('generic-str.no'),
      }).then((result) => {
        if (result.value) {
          this.fetched = false;
          webApi.put(`/pipelines/deals/${this.$route.params.id}`, {
            contact_id: null,
          }).then(
            (response) => {
              console.log('Contact add response: ', response);
              this.$toast.show({
                title: this.$t('generic-str.success'),
                content: 'Contato vinculado com sucesso!',
                type: 'success',
              });
            },
            (error) => {
              this.fetched = true;
              console.error({ error });
              this.content = error;
              this.$toast.show({
                title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
                content: error.message,
                type: 'danger',
              });
            },
          ).finally(() => {
            // this.fetched = true;
            this.contactExist = false;
            this.fetch();
          });
        }
      });
    },
    log(info) {
      console.info(info);
    },
    handleConclusion(status) {
      let msgTitle = '';
      if (status === 'resume') {
        msgTitle = 'Retomar negociação?';
      } else {
        msgTitle = `${this.$i18n.locale === 'es' ? '¿' : ''}${this.$t(`workflow.workspace.card.concluded.${status === 'done' ? 'done' : 'not-done'}.title`)}?`;
      }
      Swal.fire({
        title: msgTitle,
        // text: this.$t('workflow.workspace.task.swal.sure'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('generic-str.yes'),
        cancelButtonText: this.$t('generic-str.no'),
      }).then((result) => {
        if (result.value) {
          this.loadingConclusion = true;
          webApi
            .post(`/pipelines/deals/${this.$route.params.id}/conclusion`, {
              result: status,
            })
            .then((response) => {
              console.log('Response handleConclusion: ', response);
              this.$toast.show({
                title: this.$t('generic-str.success'),
                content: this.$t('workflow.workspace.card.edit.success'),
                type: 'success',
              });
            })
            .catch((error) => {
              console.error({ error });
              this.$toast.show({
                title: `${this.$t('sms.infos.filters.status.lbl-error')} ${
                  error.code
                }`,
                content: error.message,
                type: 'danger',
              });
            }).finally(() => {
              this.fetch();
              setTimeout(() => {
                this.loadingConclusion = false;
              }, 500);
              // this.updating = false;
            });
        } else {
          this.loadingConclusion = false;
        }
      });
    },
    insertToTeamCard() {
      const l = this.manageTeamListCard;
      const n = this.userQueryCard;

      if (!n) return;

      const exists = l.some((item) => item.id === n.id);

      if (exists) return;

      l.push(this.userQueryCard);
    },
    fetchUsersDepBoard(search, isEdit) {
      clearTimeout(this.timeout);
      const word = this.$route.path.match(/(?:)[^/]+/g)[1];
      this.timeout = setTimeout(() => {
        if (isEdit) {
          this.userAndDepCardEdit = [];
        } else {
          this.userAndDepCard = [];
        }

        webApi.get(`/pipelines/${word}/team`).then(
          (response) => {
            // console.log('Fetch user n dep response: ', response, 'query: ', search);
            this.userAndDepCard = response.data;
            this.manageTeamList = this.userAndDepCard;
            console.log('Users array: ', this.userAndDepCard);
          },
          (error) => {
            this.content = error;
          },
        );
      }, 1000);
    },
    insertToContactDeal() {
      const l = this.manageContactList;
      const n = this.contactQuery;

      if (!n) return;

      const exists = l.some((item) => item.id === n.id);

      if (exists) return;

      l.push(this.contactQuery);
    },
    insertToTeam() {
      const l = this.manageTeamList;
      const n = this.userQuery;

      if (!n) return;

      const exists = l.some((item) => item.id === n.id);

      if (exists) return;

      l.push(this.userQuery);
    },
    saveTeam() {
      const word = this.$route.path.match(/(?:)[^/]+/g)[1];
      const boardId = word;
      const team = { departments: [], users: [] };

      this.manageContactList.forEach((item) => {
        if (item.is_department) {
          team.departments.push({ id: item.id, add: true });
        } else if (item.is_user) {
          team.users.push({ id: item.id, add: true });
        }
      });

      this.savingTeam = true;

      webApi.put(`/pipelines/deals/${boardId}/team`, team).then(
        (response) => {
          this.savingTeam = false;
          this.$toast.show({
            title: this.$t('generic-str.success'),
            content: 'Novos integrantes adicionados',
            type: 'success',
          });
        },
        (error) => {
          this.savingTeam = false;
          this.$toast.show({
            title: 'Erro ao salvar',
            content: error.message,
            type: 'danger',
          });
        },
      );
    },
    fetchContacts(search, selected) {
      clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        this.contactsDeal = [];

        webApi.get(`/pipelines/deals/contacts?q=${encodeURIComponent(search)}`).then(
          (response) => {
            console.log('Fetch contacts response: ', response, 'query: ', search);
            this.contactsDeal = response.data;
            console.log('Contacts array: ', this.contactsDeal);
          },
          (error) => {
            this.content = error;
          },
        );
      }, 1000);
    },
    search() {
      this.fetched = false;
      console.info('Search function');
      const word = this.$refs.searchInput.value;
      // console.log('Query: ', word);
      let result = null;
      // let searchedObj = null;
      webApi.get(`/pipelines/deals/${this.$route.params.id}`).then(
        (response) => {
          console.log('Search response: ', response.data.columns);
          this.currentBoard = response.data;
          console.warn('currentBoard', this.currentBoard);
          response.data.columns.forEach((column, i) => {
            console.log('Item: ', column.cards.filter((item) => word.toLowerCase().split(' ').every((v) => item.title.toLowerCase().includes(v))), 'index: ', i);
            result = column.cards.filter((item) => word.toLowerCase().split(' ').every((v) => item.title.toLowerCase().includes(v)));
            this.columns[i].cards = null;
            this.columns[i].cards = result;
            console.log('Search result: ', this.columns[i].cards);
          });
          this.fetched = true;
        },
        (error) => {
          this.content = error;
        },
      );
    },
    removeResponsible() {
      if (this.userQueryCardEdit !== undefined) {
        this.userQueryCardEdit.id = 'none';
        this.userQueryCardEdit.name = '';
      } else {
        this.userQueryCardEdit = {};
        this.userQueryCardEdit.id = 'none';
        this.userQueryCardEdit.name = '';
      }
      this.seen = false;
      this.fetchUsersDepBoard();
    },
    fetch() {
      let dateString = '';
      this.fetchedBoard = false;
      this.loadingConclusion = true;
      webApi.get(`/pipelines/deals/${this.$route.params.id}`).then(
        (response) => {
          console.log('Task detail response:\n ', response.data);
          this.fetchedBoard = true;
          this.fetchedInfos = true;
          this.loadingConclusion = false;
          this.currentTask = response.data;
          dateString = this.$i18n.locale === 'en' ? 'MM/DD/YYYY h:mm:ss' : 'DD/MM/YYYY h:mm:ss';
          if (typeof this.currentTask.user === 'object') {
            this.seen = true;
          } else {
            this.seen = false;
          }
          if (this.currentTask.contact !== null) {
            this.contactExist = true;
            /* eslint-disable */
            const re = /([-a-zA-Z0-9^\p{L}\p{C}\u00a1-\uffff@:%_\+.~#?&//=]{2,256}){1}(\.[a-z]{2,4}){1}(\:[0-9]*)?(\/[-a-zA-Z0-9\u00a1-\uffff\(\)@:%,_\+.~#?&//=]*)?([-a-zA-Z0-9\(\)@:%,_\+.~#?&//=]*)?/g;
            /* eslint-enable */
            if (this.currentTask.contact.facebook) {
              // this.matchedString = this.currentTask.contact.facebook;
              const str = this.currentTask.contact.facebook;
              let match = re.exec(str);
              while (match != null) {
                console.log(match[4]); // <====
                this.matchedString.facebook = match[4] ? match[4] : '';
                match = re.exec(str);
              }
            }
            if (this.currentTask.contact.instagram) {
              // this.matchedString = this.currentTask.contact.instagram;
              const str = this.currentTask.contact.instagram;
              let match = re.exec(str);
              while (match != null) {
                console.log(match[4]); // <====
                this.matchedString.instagram = match[4] ? match[4] : '';
                match = re.exec(str);
              }
            }
            if (this.currentTask.contact.twitter) {
              // this.matchedString = this.currentTask.contact.twitter;
              const str = this.currentTask.contact.twitter;
              let match = re.exec(str);
              while (match != null) {
                console.log(match[4]); // <====
                this.matchedString.twitter = match[4] ? match[4] : '';
                match = re.exec(str);
              }
            }
            if (this.currentTask.contact.linkedin) {
              // this.matchedString = this.currentTask.contact.linkedin;
              const str = this.currentTask.contact.linkedin;
              let match = re.exec(str);
              while (match != null) {
                console.log(match[4]); // <====
                this.matchedString.linkedin = match[4] ? match[4] : '';
                match = re.exec(str);
              }
            }
          }
          this.selectedDate = new Date(this.currentTask.due_date);
          // this.$root.$emit('update:modelValue', this.selectedDate);
          this.currentTask.due_date = moment(this.currentTask.due_date, 'YYYY-MM-DD').format('DD/MM/YYYY');
          this.fetchBoardInfo(this.currentTask.column_id);
          // this.fetchOfferings();
        },
        (error) => {
          this.content = error;
        },
      );
    },
    fetchBoardInfo(e) {
      const word = this.$route.path.match(/(?:)[^/]+/g)[1];
      let columnTitleItem = null;
      let dealTitleItem = null;
      // let dateString = '';
      // this.fetched = false;
      webApi.get(`/pipelines/${word}`).then(
        (response) => {
          console.log('Board response:\n ', response);
          dealTitleItem = response.data.title;
          this.fetchedBoard = true;
          this.currentBoard = response.data.stages;
          this.boardOptions = this.currentBoard;
          console.log('TESTE: currentBoard: ', this.currentBoard, word, `\nPath: ${this.$route.path.match(/(?:)[^/]+/g)[1]}`);
          // columnTitleItem = this.currentBoard.find((item) => item.pipeline_id === word);
          columnTitleItem = this.currentTask.stage.title;
          console.log('TESTE: Filtered board info: ', columnTitleItem, `Given word: ${word}\n Path: ${this.$route.path.match(/(?:)[^/]+/g)[1]}`);
          this.breadcrumbLinks[2] = `/pipelines/${word}`;
          this.breadcrumbLinks[3] = `/pipelines/${word}/deals/${this.$route.params.id}`;
          this.breadcrumbInfo[0].value = dealTitleItem;
          this.breadcrumbInfo[1].value = columnTitleItem;
          console.log(`Bread info: ${this.breadcrumbInfo[1].value}, ${columnTitleItem}`);
          // this.currentBoard = result;
          // dateString = this.$i18n.locale === 'en' ? 'MMMM DD, YYYY' : 'DD MMMM, YYYY';
          // this.boardDate = `${moment(result.created_at).locale(this.$i18n.locale).utc().format(dateString)} - ${this.$t('app.current')}`;
          const currentStage = this.currentTask.stage;
          this.selectedStage = currentStage;
        },
        (error) => {
          this.content = error;
        },
      );
    },
    toggleBs(id) {
      this.$root.$emit('bv::toggle::collapse', id);
    },
    showModal() {
      this.$refs.addTask.show();
    },
    hideModal() {
      this.columnName = '';
      this.$refs.addTask.hide();
    },
  },
  directives: {
    // Input focus
    focus: {
      inserted(el) {
        el.focus();
      },
    },
  },
};
</script>
<style scoped lang="scss">
.page-header {
  padding-bottom: 2rem;
}
.page-content {
  padding-bottom: 2rem !important;
}

.test-sticky {
  position: sticky;
  top: 100px;
}

/* Search form style */
.form-group {
  label {
    color: var(--foreground);
    font-size: 16px;
    margin-bottom: 20px;
  }
  input:-ms-input-placeholder {
    text-transform: lowercase !important;
  }
  input::-webkit-input-placeholder {
    text-transform: lowercase !important;
  }
  input::placeholder {
    text-transform: lowercase !important;
  }

  #inputPassword {
    border-right-color: transparent;
    border-right-width: 0;
  }

  .icon_btn {
    display: flex;
    align-items: center;
    background-color: #fff;
    border: 1px solid var(--form-border);
    border-left-color: transparent;
    border-radius: 0.25rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    span {
      color: var(--gray-font-color);
    }
  }
}
.darkmode .rebranding .form-group .form-control,
.rebranding .form-group .form-control {
  border-right: unset !important;
}
.darkmode .form-group .icon_btn {
  background-color: var(--form-input);
  border-color: var(--form-border);
  border-left-color: transparent;
}
.form-wrapper {
  display: flex;
  flex-wrap: wrap;
  flex: 1 0 20%;
  .v-select {
    width: 100%;
    margin-bottom: .5rem;
  }
  .form-group {
    height: 55px;
  }
  .form-control,
  .input-group-prepend,
  .input-group,
  .icon_btn {
    height: 100%;
    span {
      color: var(--gray-font-color-5);
      font-size: 38px;
    }
  }
}
@media (min-width: 1660px){
  .form-wrapper {
    min-width: 0;
    max-width: 100%;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    flex-basis: 0;
    flex-grow: 1;
  }
}
@media (max-width: 767.98px) {
  .form-wrapper {
    flex: 1 0 40%;
  }
}
/*/Search Form style */
.container-add, .container-catalogs {
  max-width: 1740px;
}

/* List */
.title-list {
  color: var(--gray-font-color-5);
  font-weight: 400;
  display: block;
  padding-top: 8px;
  padding-bottom: 8px;
  white-space: nowrap;
  @media (min-width: 1660px) {
    padding: 0 10px;
  }
}
.options-cat {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: auto;
  gap: 10px;
  li {
    color: var(--gray-font-color-5);
  }
  .options-item.active {
    span {
      color: var(--clr-yup-purple);
    }
  }
}
.btn.btn-add-list {
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent !important;
  border: unset !important;
  font-size: 1rem;

  &:hover, &:focus {
    color: var(--clr-yup-purple-hover) !important;
    span {
      border-color: var(--clr-yup-purple-hover);
    }
  }

  span {
    margin-left: 5px;
    border: 1px solid var(--clr-yup-purple);
    border-radius: 50%;
    font-size: 18px;
    font-weight: 400;
  }
}
hr.purple {
  width: 98%;
  border-top: 5px solid #6B23DC3D;
  border-radius: 75px;
  clear: both;
}
::v-deep {
  .content-row {
    display: flex;
    background-color: var(--background-2);
    border: 1px solid #E5E5E5;
    border-radius: 8px;
    flex-wrap: wrap;
    margin-bottom: 1rem;
    >div {
      display: flex;
      align-items: center;
    }
    @media (min-width: 768px) {
      div:last-child {
        justify-content: center;
      }
    }
  }
  .task-list {
    .content-row {
      align-items: center;
      /* padding-left: 0; */
      padding-right: 0;
      >div {
        min-height: 72px;
      }
    }
  }
  .task-grid {
    width: 256px;
    min-width: 256px;
    .content-row {
      >div {
        min-height: 50px;
      }
    }
    .card:last-of-type {
      margin-bottom: 0;
    }
  }
}
.options-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1.75rem;
  margin-bottom: unset;
  li, .dropdown-toggle span {
    color: var(--gray-font-color-5);
  }
  .dropdown-toggle:hover, .dropdown-toggle:focus {
    color: var(--gray-font-color);
  }
}
#close-dialog {
  position: absolute;
  top: 6px;
  right: 10px;
  cursor: pointer;
  transform: scale(1.25);
  padding: 10px;
  background: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
#close-dialog::after {
  content: '\e5cd';
  font-family: 'Material Symbols Outlined';
  color: #FFF;
  transform: scale(1.6);
}
.tag-list {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: unset;
  li {
    color: var(--gray-font-color-5);
  }
}
::v-deep {
  .dropdown .dropdown-menu.dropdown-menu-right {
    right: auto !important;
    left: 0 !important;
  }
  .dropdown-item {
    display: flex !important;
    align-items: center !important;
    gap: 10px;
    color: var(--gray-font-color-5);
  }
  .pagination {
    justify-content: end;
  }
}
.prod-img {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  object-position: center;
}
.info-wrapper {
  display: flex;
  flex-direction: column;

  a:not(.material-symbols-outlined), span:not(.material-symbols-outlined) {
    font-weight: 400;
    padding: 8px 20px;
  }

  a {
    display: flex;
    align-items: center;
    gap: 5px;
    padding-bottom: unset !important;
  }
  span {
    display: flex;
    align-items: center;
    gap: 5px;
    padding-top: unset !important;
    color: var(--gray-font-color-5);
  }
}
/* Workflow */
::v-deep {
  .sort-descending {
    width: auto;
    height: 29px;
  }

  .filter-opt {
    display: flex;
  }

  /* Card workspace */
  .card-workspace {
    /* width: 40%; */
    margin-bottom: 0;
    /* margin-right: 50px; */
    /* flex: 0 1 45%; */
    height: 100%;

    .card-header {
      display: flex;

      .dropdown {
        margin-left: auto;
      }

      .dropdown-toggle span {
        color: var(--gray-font-color-5);
      }

      .dropdown-toggle:hover,
      .dropdown-toggle:focus {
        color: var(--gray-font-color);
      }

      span {
        height: fit-content;
      }
    }
    .card-body {
      display: flex;
      flex-direction: column;

      p {
        font-weight: bold;
        margin-bottom: 1.5rem;
      }

      .progress-yup {
        margin-top: 1.5rem;
      }
    }
    .card-footer {
      display: flex;
      gap: 5px;
      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }
    }
    small {
      color: var(--gray-font-color);
    }
  }
  .workspace-row {
    .col-workspace {
      margin-bottom: 50px;
    }
    .col-workspace:nth-of-type(2) .card.card-workspace {
      box-shadow: 0px 3px 26px 5px rgba(107, 35, 220, 0.16) !important;
    }
    @media (min-width: 768px) {
      padding: 0 8rem 0 1rem;
      .col-workspace:nth-of-type(2n) {
        // padding-left: 5rem !important;
        .card-workspace {
          margin-right: 3rem;
        }
      }
      .col-workspace:nth-of-type(2n + 1) {
        // padding-right: 5rem !important;
        .card-workspace {
          margin-left: 3rem;
        }
      }
    }
    @media screen and (min-width: 1360px) and (max-width: 1660px) {
      .col-workspace:nth-of-type(2n) {
        .card-workspace {
          margin-right: 1rem;
        }
      }
      .col-workspace:nth-of-type(2n + 1) {
        .card-workspace {
          margin-left: 1rem;
        }
      }
      padding: 0 2rem 0 1rem;
    }
  }
  .img-add {
    width: 100%;
    margin: auto;
    max-width: 250px;
    max-height: 250px;
  }
  /* @media (min-width: 768px) {
    .card-workspace {
      min-width: 480px;
    }
  } */

  .progress-yup {
    width: 100%;
    /* &:before {
      content: '';
      background-image: url(/assets/import_csv.svg);
      width: 31px;
      height: 36px;
    } */
  }
  /*/Card workspace */
  .col-calendar {
    background-color: #f7f7f7;
    padding-top: 2rem;

    .hour-wrapper {
      .card:nth-of-type(2n + 1):not(.card:first-of-type) {
        margin-left: 50px !important;
      }
    }

    > p {
      margin-bottom: 3rem;
    }
    .date-wrapper {
      display: flex;
      .date {
        font-weight: bold;
        color: var(--foreground);
      }
      .control-calendar {
        display: flex;
        gap: 6px;
        margin-left: auto;
      }
      span {
        color: var(--gray-font-color-4);
        font-weight: 400;
      }
    }
    .hour-wrapper {
      display: flex;
      flex-wrap: wrap;
      padding: 2rem 3.5rem 0 3.5rem;
      &.blank p {
        width: 100%;
      }
      /* &.scheduled p {
        height: 100%;
      } */
      hr {
        flex: 1 0 auto;
        // border-top-style: dashed;
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23E0E3E6FF' stroke-width='4' stroke-dasharray='15%2c 30' stroke-dashoffset='31' stroke-linecap='square'/%3e%3c/svg%3e");
        border: unset !important;
        height: 6%;
      }
      &.blank .badge,
      .badge.blank {
        opacity: 0;
        cursor: default;
      }

      &.scheduled .card {
        flex: 1 0 auto;
        margin-left: 15px;
        border-left: 5px solid #c2c2c2;
        .card-body,
        .card-footer {
          padding: 3px 5px;
        }
        .card-body {
          display: flex;
          color: var(--gray-font-color-4);
          font-weight: 400;

          .time {
            display: flex;
            align-items: center;
            gap: 3px;
            margin-left: auto;
            * {
              font-size: 0.875rem;
            }
          }
        }
        .card-footer {
          font-size: 0.85rem;
          padding-bottom: 5px;
        }

        // Status
        &.normal {
          border-left: 5px solid #5a99ec;

          .badge.badge-normal, .badge.badge-medium {
            border-radius: 8px;
          }
        }
        &.high {
          border-left: 5px solid #ffb200;

          .badge.badge-high {
            border-radius: 8px;
          }
        }
        &.urgent {
          border-left: 5px solid #ff0000;

          .badge.badge-high {
            border-radius: 8px;
          }
        }
      }
    }
    .hour-wrapper.active {
      hr {
        border-top: 2px solid var(--clr-yup-purple) !important;
        background-image: unset !important;
      }
    }
  }

  /* Board */
  .item-action {
    color: var(--clr-yup-purple);
    background-color: #F4EEFD;
    border-radius: 50%;
    padding: .5rem;
    font-size: 28px !important;
  }
  .board-actions {
    display: flex;
    align-items: center;
    margin-left: auto;
    gap: .5rem;
    .settings-icon {
      color: var(--gray-font-color-4);
    }
    .add-user-btn {
      transition: .5s;
      margin-right: 0;
      &.active {
        margin-right: 260px;
      }
    }
    .add-user-input {
      width: 0;
      opacity: 0;
      padding: 0;
      transition: .5s;
      position: absolute;
      right: 42px;
      max-width: 260px;
      transition-property: width, padding, opacity;
      pointer-events: unset;
      z-index: -1;
      &.active {
        width: 100%;
        opacity: 1;
        padding: .325rem;
        pointer-events: all;
        z-index: 1;
      }
    }
  }
  .team-column, .user-column, .date-wrapper {
    display: flex;
    flex-wrap: wrap;
  }
  .user-column, .date-wrapper {
    align-items: center;
  }
  .date-wrapper {
    gap: .5rem;
    margin-right: 1rem;
    span {
      color: var(--gray-font-color-4);
    }
  }
  .progress-wrapper {
    position: relative;
    // border: 1px solid #E0E3E6;
    border-radius: 8px;
    // margin: 0 5% 0 0;
    margin-top: 1rem;
    padding: 1rem;
    max-width: 88%;

    .progress {
      width: 100%;
    }

    #percentage {
      margin-top: 1rem;
      margin-left: .725rem;
      font-weight: 400;
    }

    .label-file {
      position: absolute;
      right: 40px;
      top: -10px;
      font-weight: 400;
    }

    img {
      margin-right: 1rem;
    }
  }
  .right-options {
    display: flex;
    margin-left: auto;
    span {
      color: var(--gray-font-color-4);
    }
  }
  .name-wrapper {
    display: flex;
    flex-direction: column;
    margin-left: .5rem;
    p {
      margin-bottom: .125rem;
    }
  }
  .copy-wrapper {
    display: flex;
    align-items: center;
    gap: .25rem;
    margin-left: auto;
    font-weight: 400;
  }
  .tasks-wrapper {
    display: flex;
    height: 100%;
    .collapse {
      visibility: hidden;
    }
    .collapse.show {
        visibility: visible;
        display: flex !important;
    }
    .collapsing {
        position: relative;
        height: 0;
        overflow: hidden;
        -webkit-transition-property: height, visibility;
        transition-property: height, visibility;
        -webkit-transition-duration: 0.35s;
        transition-duration: 0.35s;
        -webkit-transition-timing-function: ease;
        transition-timing-function: ease;
    }
    .collapsing.width {
        -webkit-transition-property: width, visibility;
        transition-property: width, visibility;
        transition: width .35s ease;
        width: 0;
        height: auto;
    }
    /* .collapsing.width ~ .glass {
      animation-name: fade2;
      animation-fill-mode: both;
      animation-iteration-count: 1;
      animation-duration: .5s;
      animation-direction: normal;
    } */
    .glass {
      display: flex;
      align-items: baseline;
      position: relative;
      transition: .5s;
      background-color: #6b23dc14;
      border: 1px solid;
      border-color: #e5e5e5;
      border-radius: 1rem;
      // height: 100vh;
      height: 20.6875rem;
      padding: 0 15px;
      opacity: 1;
      margin-left: 0px;
      width: 57px;
      max-width: 57px;
      p {
        color: var(--clr-yup-purple);
        position: relative;
        top: 170px;
        margin-bottom: 0;
        transform: rotate(-90deg);
        text-transform: uppercase;
        white-space: nowrap;
        width: 27px;
      }
    }
    .collapse.width ~ .glass {
      margin-right: 10px;
    }
    .collapsing.width ~ .glass {
      margin-left: -40px;
      opacity: 0;
    }
    .collapse.width.show ~ .glass {
      opacity: 0 !important;
      margin-left: -40px;
    }
    .collapse.width:not(.collapse.width.show) ~ div {
      .glass {
        display: block !important;
        position: relative;
        background: inherit;
        overflow: hidden;
        width: 70%;
        height: 70%;
        border-radius: 16px;
        box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3), 0 6px 20px rgba(0, 0, 0, 0.15);
        z-index: 1;
        opacity: 1;
      }
      .glass:before {
        content: '';
        position: absolute;
        background: inherit;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: -20px;
        box-shadow: inset 0 0 500px rgba(255, 255, 255, .4);
        filter: blur(10px);
        z-index: -1;
      }
    }
    .toggle-column {
      height: fit-content;
      padding: .75rem 0 1rem;
      position: sticky;
      top: 0;
    }
  }
  .btn-add-card:not(.col-task-grid .btn-add-card) {
    color: var(--clr-yup-purple);
    // text-decoration: underline;
    font-size: 1rem;
    font-weight: 400;
  }
  .btn-add-col, .col-task-grid .btn-add-card {
    background-color: #6b23dc14;
    border-color: #e5e5e5;
    color: var(--clr-yup-purple);
    font-weight: 400;
    border-radius: 8px;

    &:hover,
    &:focus {
      background-color: #6b23dc5e;
      color: #772ced;
    }
  }
  .col-task-grid .btn-add-card {
    width: 100%;
    height: auto;
    font-size: 1rem;
    border-radius: 1rem;
    padding: 1rem;
  }

  .main-content-size {
    width: 100%;
    // max-height: 70vh;
    // overflow: auto;
    &.sticky-content {
      overflow: unset;
      position: relative;
      z-index: 5;
    }
    .noColumn {
      position: absolute !important;
      left: 40px;
      top: 0px;
    }
    .btn-add-wrapper{
      position: relative;
      order: 2;
    }
    .btn-add-col {
      // display: flex;
      width: 20.6875rem !important;
      transform: rotate(-90deg);
      padding: 1rem;
      // flex-direction: column;
      // align-items: center;
      // gap: 1.5rem;
      // flex-shrink: 0;
      position: absolute;
      // top: 20.6875rem;
      top: 18.9rem;
      left: 35px;
      transform-origin: left;
      margin: unset !important;
      height: auto;
      font-size: 1rem;
      border-radius: 1rem;
    }
  }

  .col-name {
    color: var(--foreground);
    font-weight: bold;
    margin-bottom: 0;
  }
  .col-name ~ svg {
    margin-left: 2rem;
  }

  .lead-name {
    span {
      font-weight: 400;
    }

    .lead-infos {
      span {
        color: var(--gray-font-color-5) !important;
      }
      p {
        display: flex;
        align-items: center;
        gap: 5px;
      }
      display: flex;
      gap: 10px;
      margin-left: auto;
      @media (min-width: 1660px) {
        margin-right: 50px;
      }
    }
  }
  .owner-col {
    display: flex;
    gap: 10px;
    img {
      border-radius: 50%;
      width: 46px;
      height: 46px;
    }
  }
  .dt-expire {
    color: var(--gray-font-color-5);
    margin-bottom: 0;
    display: flex;
    align-items: center;
    gap: 5px;
  }
  .content-row.board .priority-col,
  .priority-col {
    justify-content: start;
    padding-left: 15px;
    max-width: 12%;
    /* @media (min-width: 1660px) {
      max-width: 12%;
    } */
    /* .badge {
      margin: 8px 20px;
    } */
  }
  .row-task-list {
    width: 100%;
    > .col-12.col-sm, > .col-12.col-sm-2, .col-12.col-sm-1 {
      display: flex;
      align-items: center;
    }
    .priority-col {
      padding-right: 0;
    }
    .col-name, .title-list {
      font-size: 0.875rem;
    }
    .lead-col-name {
      display: flex;
      align-items: center;
      position: sticky;
      top: 0;
      .title-list {
        display: flex;
        align-items: center;
        width: 100%;
        .col-name ~ svg {
          margin-left: auto;
        }
      }
    }
  }
  .col-task-grid {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    // width: 280px;
    // min-width: 280px;
    padding-left: .75rem;
    padding-right: .75rem;
    max-width: 280px;
    // &:not(.sticky-title) {
    //   display: flex;
    // }
    &.collapse {
      width: 0;
      opacity: 0;
    }
    &.collapse.show {
      width: auto;
      max-width: 280px;
      opacity: 1;
    }
    &.sticky-title {
      height: fit-content;
      align-items: center;
    }
    .add-column {
      width: 85%;
    }
    .lead-col-name {
      display: flex;
      align-items: center;
      float: left;
      // height: 10%;
      flex: 1 0 auto;
      width: 85%;
      // width: 100%;
      height: 50px;
      flex-wrap: wrap;
      background-color: var(--background-2);
      .title-list {
        display: flex;
        align-items: center;
        width: 100%;

        svg, span.material-symbols-outlined {
          position: relative;
          // margin-left: 10px !important;
          margin-right: 10px;
          cursor: pointer;
        }

        input {
          padding-left: 0;
          // padding-right: 0;
          height: auto;
        }
      }
    }
    .priority-col {
      float: right;
      // height: 10%;
      height: 50px;
      padding: 0;
      padding-right: 10px;
      position: relative;
      z-index: 5;
      background-color: var(--background-2);
    }
    &:not(.col-task-grid.collapsing) .priority-col, &:not(.col-task-grid.collapsing) .lead-col-name {
      position: sticky !important;
      // top: 70px;
      top: 0;
      z-index: 5;
    }
    &:not(.col-task-grid.collapsing) .priority-col ~ hr {
      position: sticky !important;
      // top: 120px;
      top: 50px;
      z-index: 5;
    }
    .col-name {
      overflow: hidden;
      max-width: 164px;
      font-size: .875rem;
    }
    .add-card-wrapper {
      width: 100%;
    }
    hr.purple {
      margin-top: 0;
      margin-bottom: 0;
    }
    .lead-col-name.isEditing {
      flex-grow: 0 !important;
      flex-shrink: 1 !important;
      width: auto;
      max-width: 85%;
    }
  }
  .board.sortable-chosen {
    background-color: #762ced11;
    // background-color: #762ced25;
    box-shadow: 0 0 25px -3px #762ced52;
  }
  .sortable-chosen {
    cursor: grab;
    &.ghost-card {
      cursor: grabbing !important;
    }
  }
  @media (min-width: 768px) {
    /* .title-row {
      padding: 0 1.5rem;
    } */
  }
  @media (max-width: 1660px) {
    .main-content {
      max-width: 100%;
      flex-basis: 100%;
    }
  }
}
.btn-close-modal {
  position: absolute;
  top: 15px;
  right: 0;
  span {
    color: var(--gray-font-color);
  }
}
::v-deep {
  #warning-text {
    display: flex;
    align-items: center;
    gap: 5px;
    width: fit-content;
    margin-top: 1rem;
    background-color: var(--urgent-bg);
    color: var(--urgent-fg);
    padding: 5px 10px;
    border-radius: 8px;
    transition: .5s;
    font-weight: 400;
    height: 100%;

    &::before {
      content: '\e000';
      font-family: 'Material Symbols Outlined';
      font-size: 1.25rem;
    }
  }

  #warning-text.error-anim {
    animation: horizontal-shaking 0.18s infinite;
  }

  @keyframes horizontal-shaking {
    0% { transform: translateX(0) }
    25% { transform: translateX(5px) }
    50% { transform: translateX(-5px) }
    75% { transform: translateX(5px) }
    100% { transform: translateX(0) }
  }
}
.filters-wrapper, .filters-row-list {
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}
.main-content-size {
  /* -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%; */
  margin-right: 0;
  margin-left: 0;
  flex-wrap: nowrap;
  overflow: auto;
  max-height: 60vh;
  height: 100%;
}

/* Board info */
.board-info {
  margin-bottom: 3.5rem;
  .title-board {
    display: flex;
    align-items: center;
    h2 {
      display: flex;
      align-items: center;
      height: 38px;
    }
    svg, span.material-symbols-outlined {
      margin-left: 1rem;
    }
    input {
      min-width: 70px;
      padding: 0;
    }
  }
  .description-board {
    p {
      text-transform: capitalize;
      color: var(--gray-font-color);
      margin-bottom: 0;
    }
  }
}

::v-deep {
  .completed {
    position: relative;
    order: 3;
    left: 70px;
    top: 10px;
    background-color: var(--sent-bg);
    color: var(--sent-fg);
    height: fit-content;
    border-radius: 50%;
    padding: .256rem;
    opacity: 0;
    pointer-events: none;
    transform: scale(.75);
    &.show {
      animation-name: fade;
      animation-fill-mode: both;
      animation-iteration-count: 1;
      animation-duration: 2s;
      animation-direction: normal;
    }
  }
  @keyframes fade{
    0% {
      opacity: 0;
    }
    60% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes fade2{
    0% {
      opacity: 0;
    }
    60% {
      opacity: .5;
    }
    100% {
      opacity: 1;
    }
  }
  .toggle-custom {
    display: flex;
    align-items: center;
    width: 100%;
    font-weight: 400;
      .material-symbols-outlined {
      margin-left: auto;
      margin-right: 5px;
      transition: .5s;
      &.rotate {
        transform: rotate(-180deg);
      }
    }
  }
  .task-grid {
    // height: 100%;
    height: calc(100% - 90px);
    &.widthTask {
      width: 256px !important;
      min-width: 256px;
    }
  }
  .input-group {
    .control-wrapper {
      display: flex;
      gap: 10px;
      align-items: center;
      font-weight: 400;
      color: var(--gray-font-color-4);
      margin-bottom: .5rem;
    }
  }
  .loading-task-request {
    position: relative;
    // top: -10px;
    // left: 45%;
    z-index: 1;
    height: fit-content;
    order: 3;
    left: 60px;
    top: 30px;
  }
  .qt-loader.qt-loader-mini.qt-loader-purple::before {
    border: 2px solid var(--clr-yup-purple);
    border-left-color: transparent;
    border-bottom-color: transparent;
  }
  /* PrimeVue */
  .pi.pi-calendar {
    font-family: 'Material Symbols Outlined';
  }
  .b-sidebar-body, .card-task, .deal-item{
    .p-calendar {
      width: 100%;
      .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
        margin-right: 0;
      }
    }
    .p-calendar-w-btn .p-inputtext {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
    .p-calendar-w-btn .p-datepicker-trigger {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
    .p-inputtext {
      border-color: var(--form-border) !important;
      border-right: none;
    }
    .p-button {
      background: transparent;
      border: 1px solid var(--form-border);
      border-left: none;
      color: var(--gray-font-color-4);
      &:hover, &:focus {
        background: unset;
        border: 1px solid unset;
      }
    }
  }
  .pi-calendar::before {
    font-size: 24px;
    content: "\e935";
  }
}

/* Sidebar add prod */
::v-deep .b-sidebar {
  background-color: var(--background-2) !important;
  width: 525px;
  small {
    color: var(--gray-font-color);
  }

  .sidebar-header {
    display: flex;
    align-items: center;
    padding-top: .5rem;
    margin-bottom: 2rem;
    .sidebar-title {
      color: var(--foreground);
      font-size: 1.25rem;
    }
    .sidebar-close {
      position: absolute;
      top: 10px;
      right: 10px;
    }
    p {
      display: block;
      font-size: 1rem;
      margin-bottom: 1px;
    }
    p, span.material-symbols-outlined {
      color: var(--gray-font-color-2);
    }
  }
  .title-column {
    margin-bottom: 2rem;
    .title-task {
      display: flex;
      align-items: center;
      margin-bottom: .5rem;
      * {
        font-size: 1.625rem;
      }
      .not-editing {
        margin-right: 1rem;
      }
    }
  }
  .responsable-column {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: .5rem;
    margin-bottom: 2.5rem;
    p {
      font-size: 1rem;
      margin-right: .5rem;
      margin-bottom: 0;
    }
    .material-symbols-outlined {
      color: var(--gray-font-color);
    }
    img {
      border-radius: 50%;
      width: 40px;
      height: 40px;
      &:last-of-type {
        margin-right: .5rem;
      }
    }
  }
  .assign-user-column {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .label-sidebar-item {
      width: fit-content !important;
      margin-right: .5rem;
    }
    svg {
      color: var(--gray-font-color);
      cursor: pointer;
      margin-top: 0 !important;
    }
  }
  .description-column {
    > p {
      font-size: 1rem;
      margin-bottom: .5rem;
    }
    & p:last-of-type {
      font-size: .875rem;
      color: var(--gray-font-color);
    }
    /* textarea {
      margin-bottom: 1px;
    } */
  }
  .btn-wrapper {
    display: flex;
    align-items: center;
  }
  .b-sidebar-body {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1.5rem;

    .sidebar-item {
      margin-bottom: 3rem;
      .label-sidebar-item {
        display: block;
        width: 100%;
        font-weight: 400;
        color: var(--foreground);
        // margin-bottom: 1.5rem;
      }
      img.profile-img {
        border-radius: 50%;
        width: 42px;
        height: 42px;
        margin-right: .5rem;
      }
      .label-sidebar-item ~ * {
        margin-top: 1rem;
      }
    }
  }
  header {
    >strong {
      order: -1;
    }
  }
  .title-column {
    p {
      color: var(--gray-font-color);
    }
  }
  textarea, select, .form-control {
    border-radius: 8px;
  }

  .input-group > .form-control:not(:last-child) {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .input-group-prepend:not(:first-child) > .btn {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .create-custom-field {
    display: flex;
    flex-wrap: wrap;

    button {
      border-radius: 8px;
      width: fit-content;
    }

    .input-group {
      display: flex;
      margin-bottom: 1.5rem;
      label {
        display: flex;
        align-items: center;
        gap: .5rem;
      }
      label:not(label.control) {
        width: 100%;
      }
      label.control ~ span {
        font-weight: 400;
        font-size: .875rem !important;
        margin-left: .5rem;
        color: var(--gray-font-color-6);
      }
    }
    .material-symbols-outlined {
      color: var(--gray-font-color);
    }
    /* .title-group {
      font-weight: 400;
      color: var(--gray-font-color-6);
      margin-bottom: 1rem;
    } */
    /* .custom-field-list {
      width: 100%;
      li {
        margin-bottom: .5rem;
        color: var(--gray-font-color-2);
        font-weight: 400;
      }
    } */
  }
}

.main-board {
  min-height: calc(100vh - 193px);

  & > .row {
    min-height: calc(100vh - 193px);
    // background-color: #fc0;

    .main-content-size {
      min-height: 60vh;
    }
  }
}
.profile-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .profile-around {
    position: relative;
    transition: .5s;
    &:hover {
      transform: scale(1.1);
      z-index: 1;
    }
    &:hover .btn-remove-responsible {
      color: var(--urgent-fg);
    }
  }
}
.profile {
  background-color: var(--clr-yup-purple);
  color: #fff;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.comment {
  margin-bottom: 1rem;
  .bar {
    display: flex;
    align-items: center;
    width: 42px;
  }

  .header-comment {
    gap: .82rem;

    time {
      color: var(--gray-font-color);
      font-size: .72em;
    }
  }

  header {
    padding-bottom: .245rem !important;
    font-weight: 400;
  }
}
.team-wrapper {
  display: flex;
  gap: 5px;
  .team-profile {
    background-color: var(--clr-yup-purple);
    color: #fff;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
/* Details */
.side-column {
  padding-top: 30px;
  background-color: var(--gray-font-color-7);
  .concluded-wrapper {
    .icon-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;
      gap: 10px;
      .icon-action {
        display: flex;
        align-items: center;
        gap: 5px;
        font-weight: 400;
        width: fit-content;
        height: fit-content;
        span.material-symbols-outlined {
          // color: var(--gray-font-color-3);
          transition: .5s;
        }
        &.done, &.done:hover, &.done:focus, &.done.active {
          color: var(--sent-fg) !important;
          /* span.material-symbols-outlined {
            color: var(--sent-fg) !important;
          } */
        }
        &.not-done, &.not-done:hover, &.not-done:focus, &.not-done.active {
          color: var(--urgent-fg) !important;
          /* span.material-symbols-outlined {
            color: var(--urgent-fg) !important;
          } */
        }
      }
    }
    .done {
      display: flex;
      gap: 5px;
      color: var(--sent-fg) !important;
    }
    .lost {
      display: flex;
      gap: 5px;
      color: var(--urgent-fg) !important;
    }
  }
  .status-wrapper {
    display: flex;
    align-items: center;
    gap: 20px;
    p.done, p.not-done {
      margin-bottom: 0;
    }
  }
}
.user-wrapper/* , .task-content */ {
  padding-top: 1rem;
  padding-bottom: 1rem;
  border: 1px solid transparent;
  border-top-width: 5px;
  border-top-color: var(--clr-yup-purple-hover-transparent);
  border-radius: 15px;
  box-shadow: 0 8px 11px -2px #0000000F;
  background-color: var(--background-2);
}
.user-wrapper {
  padding-right: 15px;
  padding-left: 15px;
  .user-item {
    display: flex;
    align-items: center;
    gap: 5px;
  }
}
.task-content {
  .tab-content {
    width: 100%;
  }
  .btn-nav-task {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    margin-bottom: .725rem;
    font-weight: 400;
    &.active {
      background-color: var(--clr-yup-purple-hover-transparent);
      color: var(--clr-yup-purple);
    }
  }
}
.show-tasks {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.tabs-detail {
  margin-bottom: 0;
  border-bottom: 4px solid #6B23DC3D;
  width: 100%;
  padding: 0 1rem;
  gap: 2.5rem;
  li {
    margin-bottom: -4px;
    button {
      display: flex;
      align-items: center;
      gap: 5px;
      padding: 1.4rem 0.7rem 1.3rem;
      color: var(--clr-light-2);
      border-radius: 0;
      &.btn-nav-task.active {
        color: var(--clr-yup-purple) !important;
        border-bottom: solid 3px var(--clr-yup-purple);
      }
    }
  }
}
// Deal details
.deal-details {
  .label-sidebar-item {
    font-weight: 400;
  }
  .deal-item {
    margin-bottom: 1.25rem;
    &.deal-item:first-of-type{
      margin-top: .25rem;
    }
    > span {
      font-weight: 400;
    }
    small{
      display: block;
      width: 100%;
      text-align: end;
      color: var(--gray-500);
    }
    input, .v-select, .p-calendar {
      margin-top: .5rem;
    }
  }
  .btn-add-responsible {
    display: flex;
    height: fit-content;
    padding: 0;
    margin-top: 0 !important;
    border-radius: 50%;
    box-shadow: 0px 0px 0px 2px var(--gray-font-color-4);
    transform: scale(.8);
    transition: .5s;
    > span {
      color: var(--gray-font-color-4);
      // font-size: 1rem;
    }
    &:hover, :focus {
      transform: scale(.9);
    }
  }
  .btn-remove-responsible {
    display: flex;
    position: absolute;
    color: var(--gray-font-color-5);
    background-color: #FFF;
    border-radius: 50%;
    top: -4px;
    right: -8px;
    padding: 0;
    height: fit-content;
    // z-index: 1;
    span {
      font-size: 1rem;
    }
  }
}
// Contact
.contact-info {
  background-color: var(--gray-font-color-8);
  padding: 1rem;
  ul.tasks-options {
    li {
      span.title {
        font-weight: 400;
      }
    }
  }
  .contact-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: .75rem;
    .channels {
      width: 100%;
      margin-top: .75rem;
      margin-bottom: 0;
      ul {
        display: flex;
        gap: 2.5rem;
        justify-content: center;
        margin-bottom: 0;
      }
    }
    .person-icon {
      background-color: var(--gray-font-color-3);
      color: var(--gray-font-color-5);
      padding: .5rem;
      padding-left: .59rem;
      margin-bottom: .5rem;
      border-radius: 50%;
      font-size: 2.5rem;
      cursor: default;
    }
    p {
      width: 100%;
      text-align: center;
      font-size: .75rem;
    }
    ul.contact-list-items {
      width: 100%;
      min-height: 65%;
      list-style-type: none;
      padding: 0;
      margin: 0;
      li {
        display: flex;
        align-items: center;
        margin: 0;
        padding: .62rem 0;
        &::after {
          clear: both;
          content: "";
          height: 1px;
          display: block;
        }
        .icn {
          float: left;
          width: 32px;
        }
        .material-icons-outlined, .material-symbols-outlined {
          color: #752de6;
          font-size: 1.4rem;
          vertical-align: middle;
          margin-right: .5rem;
          cursor: default;
        }
        figure {
          display: inline-block;
          height: 24px;
          width: 24px;
          border-radius: 12px;
          overflow: hidden;
          margin-bottom: 0;
        }
      }
    }
  }
}
// Deal
.deal-title {
  font-size: 1.25rem;
  font-weight: 400;
}

// History
::v-deep {
  .log-wrapper {
    .card {
      box-shadow: unset !important;
    }
  }
}

// Skeleton loading
.animated-background {
  width: fit-content;
  height: fit-content;
  color: transparent !important;
  pointer-events: none !important;
}
input.animated-background, input.animated-background.form-control {
  width: 100%;
  // min-height: 48px;
  border: 1px solid transparent !important;
  pointer-events: none !important;
}
::v-deep {
  .p-calendar.animated-background {
    min-height: 42px;
    * {
      opacity: 0 !important;
    }
  }
  .deal-item {
    .v-select {
      margin-top: .25rem !important;
    }
  }
  .v-select.animated-background {
    // margin-top: .25rem !important;
    height: 40px;
    * {
      opacity: 0 !important;
    }
  }
}
.dummy-scale {
  width: 50px;
  height: 22px;
}

// Lottie loading
.concluded-loading {
  max-height: 42px;
}
</style>
