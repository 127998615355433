<template>
  <div class="log-wrapper">
    <CardComponent ref="card" variant="tertiary">
      <template #header>
        <h2>{{ $t('generic-str.user-activity-logs') }}</h2>
        <ul class="actions top-right">
          <li>
            <a href="javascript:void(0)" @click="showFilter">
              <span class="material-symbols-outlined title"> filter_list </span>
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="fetch(1)">
              <span class="material-icons-outlined title"> refresh </span>
            </a>
          </li>
        </ul>
      </template>
      <TableComponent :busy="loading" :fields="fields" :items="logs">
        <template #cell(user)="slotProps">
          <AvatarComponent :image="slotProps.data.profile_picture"
                           :name="slotProps.data.name"></AvatarComponent>
          {{ slotProps.data.name }}
        </template>
        <template #cell(account)="slotProps">
          <div class="row">
            <div class="col-3">
              <AvatarComponent :image="slotProps.data.profile_picture"
                               :name="slotProps.data.name"></AvatarComponent>
            </div>
            <div class="col">
              <div>{{ slotProps.data.name }}</div>
              <a href="javascript:void(0)" @click="copyIp(slotProps.item.ip)">
                {{ slotProps.item.ip }}
                <span class="material-symbols-outlined">content_copy</span>
              </a>
            </div>
          </div>
        </template>
        <template #cell(tags)="slotProps">
          <BadgeComponent variant="light">{{ slotProps.data }}</BadgeComponent>
        </template>
        <template #cell(code)="slotProps">
          <BadgeComponent v-if="slotProps.data === 200" variant="success">Sucesso</BadgeComponent>
          <BadgeComponent v-else variant="danger">Falha</BadgeComponent>
        </template>
        <template #cell(actions)="slotProps">
          <button
            class="btn btn-personalize-log mr-5"
            @click="showEventLog(slotProps.item)"
          >
            <span class="material-symbols-outlined fill-icon">visibility</span>
            {{ $t('generic-str.view-details') }}
          </button>
        </template>
      </TableComponent>
      <pagination v-model="currentPage" :total-pages="pages" @change="fetch"/>
    </CardComponent>
    <SidebarComponent
      ref="filter"
      :title="$tc('app.filter', 2)"
      backdrop
    >
      <div class="d-flex flex-column gap-2 pb-2">
        <p>{{ $tc('contacts-component.filter_description') }}</p>
      </div>
      <div class="d-flex flex-column gap-2 pb-2">
        <div class="form-group">
          <strong>{{ $t('generic-str.filter-by-date-and-time') }}</strong>
          <!-- <input type="date" class="data" v-model="filter.date" /> -->
          <date-range-picker
            :format="'YYYY-MM-DD'"
            :options="options"
            class="form-control"
            @change="changeDate"
          />
        </div>
      </div>
      <div class="d-flex flex-column gap-2 pb-2">
        <strong>{{ $t('generic-str.search') }}</strong>
        <InputComponent v-model="filter.search"
                        :placeholder="$t('generic-str.search-by-username-account-or-action')"
                        type="text"></InputComponent>
      </div>
      <div class="d-flex flex-column gap-2 pb-2">
        <strong>{{ $t('generic-str.filter-by-status') }}</strong>
        <SelectComponent
          v-model="filter.status"
          :options="statuses"
        ></SelectComponent>
      </div>
      <template #footer>
        <div class="d-flex justify-content-between">
          <ButtonComponent variant="secondary" @click="cleanFilter">
            {{ $t('app.clean') }}
          </ButtonComponent>
          <ButtonComponent @click="applyFilter">
            {{ $t('app.apply') }} {{ $tc('app.filter', 2) }}
          </ButtonComponent>
        </div>
      </template>
    </SidebarComponent>
    <SidebarComponent
      ref="eventLog"
      :title="$t('generic-str.event-log')"
      backdrop
      class="filter-log"
    >
      <div class="container-fluid px-0">
        <div class="row no-gutters">
          <template v-if="currentLog.payload">
            <div class="col-4 icon warning">
              <p class="mb-0">{{ currentLog.created_at | formatDate24 }}</p>
            </div>
            <div v-b-toggle.collapse-1 class="col">
              <p class="mb-0">{{ currentLog.content }}</p>
              <!-- <button class="btn toggle-detail stretched-link" v-b-toggle.collapse-1></button> -->
            </div>
            <div class="col-12 detail-wrapper">
              <b-collapse id="collapse-1" class="mt-2">
                <div class="container-fluid px-0">
                  <div class="row no-gutters">
                    <template
                      v-if="currentLog.operation !== 'destroy' && currentLog.tags !== 'email'">
                      <div v-for="(payload, i) in currentLog.payload" :key="i"
                           :class="`col item item-${i}`">
                        <template v-if="i === 'new' || i === 'old'">
                          <div v-if="currentLog.tags !== 'deal'" :class="`${i}-value`">
                            <template>
                              <p v-for="(data, attr) in payload" :key="attr" class="val-wrapper">
                                {{ attr }}: {{ data }}
                              </p>
                            </template>
                          </div>
                          <!-- Deal -->
                          <div v-if="currentLog.tags === 'deal'" :class="`${i}-value`">
                            <div v-if="payload.title" class="val-wrapper">
                              <span>{{ payload.title }}</span></div>
                            <div v-if="payload.description" class="val-wrapper">
                              <span>{{ payload.description }}</span></div>
                            <div v-if="payload.due_date" class="val-wrapper">
                              <span>{{ payload.due_date | formatDate24 }}</span></div>
                          </div>
                        </template>
                        <!-- Task | Lembrete -->
                        <template v-if="i === 'task'">
                          <div :class="`${i}-value`">
                            <div class="val-wrapper">{{ $tc('app.title', 1) }}:
                              <span>{{ payload.title }}</span></div>
                            <div class="val-wrapper">{{ $t('app.description') }}:
                              <span>{{ payload.description }}</span></div>
                            <div class="val-wrapper">Criado em:
                              <span>{{ payload.created_at | formatDate24 }}</span></div>
                          </div>
                        </template>
                      </div>
                    </template>
                    <template v-else-if="currentLog.operation === 'destroy'">
                      <div class="destroy-value">
                        <div class="val-wrapper">
                          <pre>{{ JSON.stringify(currentLog.payload.item, null, 2) }}</pre>
                        </div>
                      </div>
                    </template>
                    <template v-if="currentLog.payload.message">
                      <div class="values">
                        <div class="val-wrapper">
                          <span>{{
                              currentLog.payload.message.content
                            }}{{ currentLog.payload.message.date | formatDate24 }}</span>
                        </div>
                      </div>
                    </template>
                    <template v-if="currentLog.tags === 'email'">
                      <div class="container-fluid px-0 email-container">
                        <div class="row no-gutters">
                          <div class="col values">
                            <div class="head-values">
                              <div class="val-wrapper">De:
                                <span>{{ currentLog.payload.from }}</span></div>
                              <div class="val-wrapper">Para:
                                <span>{{ currentLog.payload.message[0].to }}</span></div>
                              <div class="val-wrapper">Nome:
                                <span>{{ currentLog.payload.message[0].from_name }}</span></div>
                              <div class="val-wrapper">Assunto:
                                <span>{{ currentLog.payload.message[0].subject }}</span></div>
                              <div class="val-wrapper">Email resposta:
                                <span>{{ currentLog.payload.message[0].reply_email }}</span></div>
                            </div>
                            <div class="body-values">
                              <p class="mb-0">Conteúdo: </p>
                              <div class="content-value" v-html="currentLog.payload.body"></div>
                            </div>
                            <div class="info-values">
                              <div class="val-wrapper">Tipo:
                                <span>{{ currentLog.payload.message[0].source }}</span></div>
                              <div class="val-wrapper">Status:
                                <span>{{ currentLog.payload.message[0].status }}</span></div>
                              <div v-if="currentLog.payload.message[0].error_code"
                                   class="val-wrapper">Código de erro:
                                <span>{{ currentLog.payload.message[0].error_code }}</span></div>
                              <div v-if="currentLog.payload.message[0].error_message"
                                   class="val-wrapper">Mensagem de
                                erro: <span>{{ currentLog.payload.message[0].error_message }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
              </b-collapse>
            </div>
          </template>
          <template v-else>
            <div class="col-4 icon warning">
              <p class="mb-0">{{ currentLog.created_at | formatDate24 }}</p>
            </div>
            <div class="col">
              <p class="mb-0">{{ currentLog.content }}</p>
            </div>
          </template>
        </div>
        <div class="row no-gutters">
          <div class="col-4 icon">
            <p class="mb-0">{{ $t('generic-str.action') }}</p>
          </div>
          <div class="col">
            <p class="mb-0 text-capitalize">{{ currentLog.tags }}</p>
          </div>
        </div>
        <div class="row no-gutters">
          <div class="col-4 icon">
            <p class="mb-0">ID</p>
          </div>
          <div class="col">
            <p class="mb-0">{{ currentLog.id }}</p>
          </div>
        </div>
        <div v-if="currentLog.user" class="row no-gutters">
          <div class="col-4 icon user">
            <p class="mb-0">{{ $tc('app.user', 1) }}</p>
          </div>
          <div class="col">
            <p class="mb-0">{{ currentLog.user.name }}</p>
          </div>
        </div>
        <div v-if="currentLog.account" class="row no-gutters">
          <div class="col-4 icon badge">
            <p class="mb-0">{{ $tc('app.account', 1) }}</p>
          </div>
          <div class="col">
            <p class="mb-0">{{ currentLog.account.name }}</p>
          </div>
        </div>
        <div v-if="currentLog.device" class="row no-gutters">
          <div class="col-4 icon">
            <p class="mb-0">{{ $tc('generic-str.device', 1) }}</p>
          </div>
          <div class="col">
            <p class="mb-0">{{ currentLog.device }}</p>
          </div>
        </div>
        <div v-if="currentLog.device" class="row no-gutters">
          <div class="col-4 icon mobile">
            <p class="mb-0">IP</p>
          </div>
          <div class="col">
            <p class="mb-0">{{ currentLog.ip }}</p>
          </div>
        </div>
        <div v-if="loading || (!currentLog || currentLog.length === 0)" class="p-4 text-center">
          <b-spinner class="spinner-color" label="Spinning"></b-spinner>
        </div>
      </div>
    </SidebarComponent>
  </div>
</template>
<script>
import Pagination from '@/components/rebranding/pagination/Pagination.vue';
import axios from '@/plugins/axios';
import TableComponent from '@/components/TableComponent.vue';
import InputComponent from '@/components/form/InputComponent.vue';
import SidebarComponent from '@/components/SidebarComponent.vue';
import ButtonComponent from '@/components/ui/ButtonComponent.vue';
import SelectComponent from '@/components/form/SelectComponent.vue';
import moment from 'moment';
import AvatarComponent from '@/components/ui/AvatarComponent.vue';
import BadgeComponent from '@/components/ui/BadgeComponent.vue';
import CardComponent from '@/components/CardComponent.vue';

const webApi = axios();

export default {
  name: 'LogDetail',
  components: {
    CardComponent,
    BadgeComponent,
    AvatarComponent,
    Pagination,
    TableComponent,
    InputComponent,
    SidebarComponent,
    ButtonComponent,
    SelectComponent,
  },
  props: {
    dealId: {
      type: String,
    },
  },
  data() {
    return {
      currentLog: [],
      currentPage: 1,
      pages: 1,
      fetched: true,
      loading: true,
      logs: [],
      tableFields: [
        {
          key: 'id',
          label: `${this.$tc('app.id', 1)} ${this.$t('options-div.event-title')}`,
        },
        {
          key: 'created_at',
          label: this.$t('app.creation_date'),
          type: 'datetime',
        },
        {
          key: 'user',
          label: this.$tc('app.user', 1),
          type: 'string',
        },
        {
          key: 'account',
          label: this.$tc('generic-str.acc', 1),
        },
        {
          key: 'content',
          label: this.$t('app.description'),
        },
        {
          key: 'tags',
          label: this.$t('generic-str.action'),
        },
        {
          key: 'code',
          label: 'Status',
        },
      ],
      fields: [
        {
          key: 'id',
          label: `${this.$tc('app.id', 1)} ${this.$t('options-div.event-title')}`,
        },
        {
          key: 'created_at',
          label: this.$t('app.creation_date'),
          formatter: (value) => moment(value).format('DD/MM/YYYY HH:mm:ss'),
        },
        'user',
        'account',
        {
          key: 'content',
          label: this.$t('app.description'),
        },
        {
          key: 'tags',
          label: this.$t('generic-str.action'),
        },
        {
          key: 'code',
          label: 'Status',
        },
        'actions',
      ],
      totalItems: 0,
      filter: {
        status: [],
        // date: '',
        search: '',
        orderBy: '',
        startDate: moment().format('YYYY-MM-DD 00:00:00'),
        endDate: moment().format('YYYY-MM-DD 23:59:59'),
      },
      statuses: [{ text: this.$t('app.success'), value: '200' }, {
        text: this.$t('app.error'),
        value: 'error',
      }],
      form: {
        date: {
          gte: moment().format('YYYY-MM-DD'),
          lte: moment().format('YYYY-MM-DD 23:59:59'),
        },
      },
      options: {
        timePicker: false,
        autoApply: true,
        className: 'form-control',
        startDate: moment().format('DD/MM/YYYY'),
        maxSpan: {
          days: 31,
        },
        locale: {
          format: 'DD/MM/YYYY',
          daysOfWeek: ['Do', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sa'],
          monthNames: [
            'Jan',
            'Fev',
            'Mar',
            'Abr',
            'Mai',
            'Jun',
            'Jul',
            'Ago',
            'Set',
            'Otu',
            'Nov',
            'Dez',
          ],
        },
      },
    };
  },
  mounted() {
    this.$root.$on('refreshLogList', () => {
      this.fetch();
    });
    console.log('Log List:: ', this);
  },
  beforeDestroy() {
    // Destroying instance to avoid multiple future further calls
    this.$root.$off('refreshLogList');
  },
  watch: {
    fetched: {
      handler(val, oldVal) {
        console.log(
          'Watch: fetched watch: ',
          val,
          '\nWatch: fetched old value: ',
          oldVal,
        );
        // this.sortTable();
      },
      deep: true,
      immediate: false,
    },
  },
  created() {
    setTimeout(() => {
      this.fetch(1);
    }, 500);
  },
  methods: {
    changeDate(date) {
      if (date) {
        this.filter.startDate = moment(date[0]).format('YYYY-MM-DD 00:00:00');
        this.filter.endDate = moment(date[1]).format('YYYY-MM-DD 23:59:59');
      }
    },
    fetch(page) {
      this.loading = true;
      this.currentPage = page;
      let query = '';
      if (this.dealId) {
        query = `/custom-logs?page=${this.currentPage}&deal_id=${this.dealId}`;
      } else if (this.filter.status || this.filter.search || (this.filter.startDate && this.filter.endDate)) {
        const startDate = this.filter.startDate;
        const endDate = this.filter.endDate;
        const action = this.filter.search;
        const status = this.filter.status === 'error' ? 'type' : 'code';
        query = `/custom-logs?page=${this.currentPage}&filter[${status}]=${status === 'type' ? 'error' : 200}&filter[tags]=${action}&start_date=${startDate}&end_date=${endDate}`;
      } else {
        query = `/custom-logs?page=${this.currentPage}`;
      }
      webApi.get(query).then(
        (response) => {
          console.log('Log response:\n ', response);
          this.loading = false;
          this.logs = response.data.data;
          this.totalItems = this.logs.length;
          this.pages = response.data.last_page;
        },
        (error) => {
          this.content = error;
        },
      );
    },
    showFilter() {
      this.$refs.filter.toggle();
    },
    showEventLog(log) {
      console.debug('Current log userId: ', log);
      this.currentLog = log;
      this.$refs.eventLog.toggle();
    },
    cleanFilter() {
      this.filter = {
        status: [],
        // date: '',
        search: '',
        orderBy: '',
        startDate: moment().format('YYYY-MM-DD 00:00:00'),
        endDate: moment().format('YYYY-MM-DD 23:59:59'),
      };
      console.log('Filtros limpos com sucesso!');
      this.form.filter = {};
      this.$refs.filter.toggle();
      this.fetch(1);
    },
    applyFilter() {
      // Chamar fetch() para recarregar os dados com os filtros aplicados
      this.fetch(1);
      this.$refs.filter.toggle();
    },
    copyIp(ip) {
      navigator.clipboard.writeText(ip).then(() => {
        this.$toast.show({
          title: this.$t('app.copied'),
          type: 'success',
        });
      });
    },
  },
  filters: {
    maskPhone(phone) {
      if (!phone) return '';

      phone = phone.replace(/\D+/g, '').substring(0, 13);

      switch (phone.length) {
        case 13:
          phone = phone.replace(/^(\d{2})(\d{2})(\d{5})(\d{4})$/, '+$1 ($2) $3-$4');
          break;
        case 12:
          phone = phone.replace(/^(\d{2})(\d{2})(\d{4})(\d{4})$/, '+$1 ($2) $3-$4');
          break;
        case 11:
          phone = phone.replace(/^(\d{2})(\d{2})(\d{5})(\d{4})$/, '+55 ($2) $3-$4');
          break;
        case 10:
          phone = phone.replace(/^(\d{2})(\d{2})(\d{4})(\d{4})$/, '+55 ($2) $3-$4');
          break;
        default:
      }
      return phone;
    },
  },
};
</script>
<style lang="scss">
$base-spacing-unit: 24px;
$half-spacing-unit: $base-spacing-unit / 2;

$color-alpha: #1772ff;
$color-form-highlight: #f7f9fb;
$color-gray: #667085;
$border: #E4E7EC;

.table {
  width: 100%;
  // border:1px solid $color-form-highlight;
}

.table-header {
  display: flex;
  width: 100%;
  // background:#000;
  padding: ($half-spacing-unit * 1) 0;
}

.table-row {
  display: flex;
  width: 100%;
  padding: ($half-spacing-unit * 1.5) 0;

  &:nth-of-type(odd) {
    // background:$color-form-highlight;
  }
}

.table-data,
.header__item {
  flex: 1 1 20%;
  // text-align:center;
  &:first-child {
    max-width: 8%;
    margin-right: 1rem;
  }

  /* &:last-child, &:nth-child(3){
    max-width: 5%;
  } */
}

.header__item {
  // text-transform:uppercase;
  font-weight: 400;
}

.filter__link {
  color: var(--foreground);
  text-decoration: none;
  position: relative;
  display: inline-block;
  padding-left: $base-spacing-unit;
  padding-right: $base-spacing-unit;

  &::after {
    content: "";
    position: absolute;
    right: -($half-spacing-unit * 1.5);
    color: var(--foreground);
    font-size: $half-spacing-unit;
    top: 50%;
    transform: translateY(-50%);
    font-family: "Material Symbols Outlined";
    font-weight: normal;
    font-style: normal;
    font-size: 20px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -moz-font-feature-settings: "liga";
    -moz-osx-font-smoothing: grayscale;
  }

  &.desc::after {
    content: "\e5db"; // (desc)
  }

  &.asc::after {
    content: "\e5d8"; // (asc)
  }
}

.card .card-header .list-group-item {
  padding: 0;
}

.card .card-header[data-v-ea1bcd84] {
  padding: 0;
  margin-top: 5px;
}

.btn-personalize-log {
  width: 200px;
  color: #5a99ec;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.filter-log {
  h1 {
    font-size: 20px;
    font-weight: 600;
    color: #423c4c;
  }

  strong {
    font-size: 16px;
    font-weight: 600;
    color: #423c4c;
  }

  .form-check-label {
    color: #707070;
    font-weight: 500;
    cursor: pointer;
    margin-left: 10px;
  }

  .form-check-input {
    cursor: pointer;
    width: 18px;
    height: 18px;
    background-color: #f0f0f2;
    border: 1px solid #d6dadd;
    border-radius: 3px;
  }

  .icon_btn {
    display: flex;
    align-items: center;
    background-color: #fff;
    border: 1px solid var(--form-border);
    border-left-color: var(--form-input);
    border-radius: 0.25rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    padding-right: 15px !important;

    span {
      color: var(--gray-font-color);
    }
  }

  .data {
    width: 420px;
    height: 50px;
    border-radius: 6px;
    border: 1.5px solid #b7b7b7;
    opacity: 60%;
    padding: 20px;
    color: #707070;
  }

  input[type="date"]::-webkit-calendar-picker-indicator {
    font-size: 22px;
    cursor: pointer;
  }

  input[type="date"]::placeholder {
    font-weight: 500;
    color: #707070;
    cursor: pointer;
  }

  .buscar {
    width: 420px;
    height: 50px;
    border-radius: 6px;
    border: 1.5px solid #b7b7b7;
    opacity: 60%;
    padding: 20px;
  }

  .buscar::placeholder {
    color: #707070;
    font-weight: 500;
    cursor: pointer;
  }

  .order {
    width: 420px;
    height: 50px;
    border-radius: 6px;
    border: 1.5px solid #b7b7b7;
    opacity: 60%;
    padding: 0 20px;
    cursor: pointer;
  }

  .order::placeholder {
    color: #707070;
    font-weight: 500;
    margin-right: 20px;
    cursor: pointer;
  }

  .gap-2 {
    gap: 10px;
  }

  .row.no-gutters {
    padding: .85rem 0;
    border-top: 1px solid $border;

    p {
      font-size: .75rem;
    }

    > div.icon:first-child {
      display: flex;
      justify-content: flex-end;

      &::after {
        content: '•';
        margin-left: 10px;
        width: 20px;
        display: flex;
        justify-content: center;
        color: $color-gray;
      }

      &.icon::after {
        font-family: 'Material Symbols Outlined';
        font-weight: normal;
        font-style: normal;
        font-size: 20px;
        line-height: 1;
        letter-spacing: normal;
        text-transform: none;
        display: inline-block;
        white-space: nowrap;
        word-wrap: normal;
        direction: ltr;
        -moz-font-feature-settings: 'liga';
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
      }

      &.mobile {
        &::after {
          content: '\e1b1';
        }
      }

      &.badge {
        padding: 0 !important;

        &::after {
          content: '\ea67';
        }
      }

      &.user {
        padding: 0 !important;

        &::after {
          content: '\e853';
        }
      }

      &.warning {
        padding: 0 !important;
        margin-right: 10px;

        &::after {
          content: '\e002';
          color: #DB4446;
        }
      }

      p {
        text-align: right;
        color: $color-gray;
      }
    }

    > div:last-child {
      display: flex;
      padding-left: 10px;
      /* &::before {
        content: '•';
        margin-right: 10px;
      }
      &.icon {
        font-family: 'Material Symbols Outlined';
        font-weight: normal;
        font-style: normal;
        font-size: 20px;
        line-height: 1;
        letter-spacing: normal;
        text-transform: none;
        display: inline-block;
        white-space: nowrap;
        word-wrap: normal;
        direction: ltr;
        -moz-font-feature-settings: 'liga';
        -moz-osx-font-smoothing: grayscale;
      }
      &.mobile {
        &::before {
          content: '\e1b1'
        }
      } */
    }
  }
}

.status-filter {
  width: 32%;
  height: 20%;
  border-radius: 6px;
  border: 1.5px solid #b7b7b7;
  opacity: 60%;
  padding: 10px;
  cursor: pointer;
}

.status-filter::placeholder {
  color: #707070;
  font-weight: 500;
  cursor: pointer;
}

.cursor {
  cursor: pointer;
}

.opts_card i {
  font-size: 22px;
  transition: 0.5s;
  padding: 5px;
}

.opts_card i:hover {
  background-color: var(--background-icon-hover);
  border-radius: 5px;
}

.title {
  color: #697077 !important;
  font-size: 28px;
}

.card-log {
  padding: 30px;
}

// Styles Log list sideBar

.userName-log {
  color: #0099ff;
  font-weight: 400;
  vertical-align: top;
  width: 100%;
}

.description-log {
  color: #101828;
  width: 70%;
  font-size: 13px;
  font-weight: 400;
  vertical-align: top;
  text-align: start;
}

.item-log {
  color: #667085;
  width: 30%;
  font-size: 12px;
  font-weight: 400;
  vertical-align: top;
  text-align: end;
}

.icon-log {
  width: 20%;
  padding: 10px;
  font-size: 20px;
  color: #667085;
  vertical-align: top;
}

.dot-icon-log {
  width: 20%;
  padding: 10px;
  vertical-align: top;
  font-size: 12px;
  color: #667085;
}

.icon-log-alert {
  width: 20%;
  color: #db4446;
  padding: 10px;
  font-size: 20px;
  vertical-align: top;
}

.card-event-log {
  padding: 5px;
  align-items: center;
  display: flex;
  margin: 10px;
  border-bottom: 1px solid #e4e7ec;
}

.spinner-color {
  color: #b690f1;
  width: 2rem;
  height: 2rem;
  border-width: 3px;
}

.detail-wrapper {
  > div {
    width: 100%;
  }

  .val-wrapper {
    font-weight: 400;
  }

  .old-value {
    margin-right: 0.5rem;

    .val-wrapper {
      color: var(--urgent-fg) !important;
    }
  }

  .item {
    display: flex;
  }

  .item-new {
    .val-wrapper {
      &::before {
        content: '<<' !important;
        color: var(--sent-fg) !important;
        padding-right: 5px;
      }

      &::after {
        content: '' !important;
        color: var(--sent-fg) !important;
      }
    }
  }

  .item-old {
    .val-wrapper {
      &::before {
        content: '' !important;
        color: var(--urgent-fg) !important;
      }

      &::after {
        content: '>>' !important;
        color: var(--urgent-fg) !important;
        padding-left: 5px;
      }
    }
  }

  .new-value {

    .val-wrapper {
      text-align: end;
      color: var(--sent-fg) !important;
    }
  }

  .email-container {
    .col.values {
      flex-wrap: wrap;
    }

    .head-values, .body-values, .info-values {
      width: 100%;
    }

    .head-values, .body-values {
      margin-bottom: .325rem;
    }

    .body-values {
      border: 1px solid $color-gray;
      background-color: #edf0f7;
      border-radius: 8px;
      padding: 5px 5px 0 5px;
    }
  }
}

</style>
