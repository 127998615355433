<template>
  <!-- Task 1 -->
  <div class="card card-task">
    <div class="card-header">
    <div class="task-title">{{item.title}}</div>
    <div class="options-task">
      <ul class="list-unstyled">
        <!-- <li v-if="item.done_at"><button class="btn" @click="item.user_id === userId ? markAsDone(item.id, 'unmark') : null"><span class="material-symbols-outlined done">done</span></button></li>
        <li v-else><button class="btn" @click="item.user_id === userId ? markAsDone(item.id, 'mark') : null"><span class="material-symbols-outlined not-done">check_indeterminate_small</span></button></li>
        <li><button v-if="item.user_id === userId" class="btn" @click="sendDetails(item)"><span class="material-symbols-outlined edit-icon"> edit </span></button></li> -->
        <li><small><span class="material-symbols-outlined">schedule</span><time :datetime="item.due_date">{{item.due_date | displayDate(months, today, $i18n.locale)}}</time></small></li>
        <li class="btn-delete-wrapper"><button v-if="item.user_id === userId" class="btn" @click="deleteTask(item.id)"><span class="material-symbols-outlined delete-icon">delete_forever</span></button></li>
      </ul>
    </div>
    </div>
    <div class="card-body">
      <div v-if="!descriptionOverflow">
        <div :id="`taskDesc-${index}`" class="task-description">
          {{item.description}}
        </div>
      </div>
      <div v-else :id="`collapse-desc-${item.id}`" class="w-100 collapse collapse-preview">
          <div :id="`taskDesc-${index}`" class="task-description">
            {{item.description}}
          </div>
      </div>
      <div v-if="descriptionOverflow" class="backdrop-preview"></div>
      <button v-if="descriptionOverflow" class="btn toggle-custom" data-toggle="collapse" :data-target="`#collapse-desc-${item.id}`" aria-expanded="false" :aria-controls="`collapse-desc-${item.id}`" @click="showLabel = !showLabel, customToggle = !customToggle">{{$t(`app.show-${showLabel ? 'more' : 'less'}`)}}<span class="material-symbols-outlined" :class="{rotate: customToggle}">expand_more</span></button>
    </div>
    <div class="card-footer">
      <ul class="list-unstyled">
        <li v-if="item.done_at"><button class="btn btn-undone" @click="item.user_id === userId ? markAsDone(item.id, 'unmark') : null">Retomar</button></li>
        <li v-else><button class="btn btn-done" @click="item.user_id === userId ? markAsDone(item.id, 'mark') : null">Concluir</button></li>
        <li :id="`popover-target-${item.id}`"><button v-if="item.user_id === userId" class="btn btn-edit" @click="sendDetails(item)" :disabled="item.done_at !== null">{{$t('app.edit')}}</button></li>
        <b-popover :target="`popover-target-${item.id}`" triggers="hover" placement="top">
          <span v-if="item.done_at !== null">Retome o lembrete para editar!</span>
        </b-popover>
      </ul>
    </div>
  </div>
  <!--/Task 1 -->
</template>
<script>
import Swal from 'sweetalert2';
import axios from '@/plugins/axios';

const webApi = axios();

export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    index: {
      type: Number,
    },
  },
  computed: {
    userId() {
      return this.$store?.state?.auth?.user?.id;
    },
  },
  filters: {
    displayDate(date, monthNames, localeToday, locale) {
      const today = new Date();
      const current = new Date(date);

      if (today.toDateString() === current.toDateString()) {
        return localeToday;
      }

      const months = monthNames;
      const y = current.getFullYear();
      const m = current.getMonth();
      const d = `${current.getDate()}`.padStart(2, 0);

      return (locale === 'en' ? `${months[m]} ${d}, ${y}` : `${d} ${months[m]}, ${y}`);
    },
  },
  data() {
    return {
      descriptionOverflow: false,
      months: this.$t('primevue.monthNames'),
      today: this.$t('primevue.today'),
      showLabel: true,
      customToggle: true,
    };
  },
  mounted() {
    setTimeout(() => {
      const taskDesc = document.getElementById(`taskDesc-${this.index}`);
      // console.log('El height: ', taskDesc.offsetHeight);
      taskDesc.offsetHeight > 36 ? this.descriptionOverflow = true : this.descriptionOverflow = false;
    }, 200);
  },
  methods: {
    sendDetails(item) {
      this.$emit('taskToEdit', item);
    },
    markAsDone(id, type) {
      Swal.fire({
        title: this.$t(`workflow.workspace.task.swal.${type}-as-done`),
        // text: this.$t('workflow.workspace.task.swal.sure'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('generic-str.yes'),
        cancelButtonText: this.$t('generic-str.no'),
      }).then((result) => {
        if (result.value) {
          webApi.post(`/pipelines/tasks/${id}`).then((response) => {
            console.log(`Task: markAsDone ->\n${response}`);
            this.$toast.show({
              title: this.$t('generic-str.success'),
              content: 'Tarefa alterada com sucesso!',
              type: 'success',
            });
            this.$emit('update');
          }).catch((ee) => {
            const content = ee?.response?.data?.message ?? String(ee);
            this.isSending = false;
            this.$toast.show({
              title: 'Tarefa',
              content,
              type: 'danger',
            });
            this.$emit('update');
          });
        }
      }).finally(() => {
        this.$root.$emit('refreshLogList'); // Refresh deal log list
      });
    },
    deleteTask(id) {
      Swal.fire({
        title: this.$t('workflow.workspace.task.swal.remove-task'),
        text: this.$t('workflow.workspace.task.swal.sure'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('generic-str.yes'),
        cancelButtonText: this.$t('generic-str.no'),
      }).then((result) => {
        if (result.value) {
          webApi.delete(`/pipelines/tasks/${id}`).then((response) => {
            console.log(`Task: delete ->\n${response}`);
            this.$toast.show({
              title: this.$t('generic-str.success'),
              content: 'Tarefa deletada com sucesso!',
              type: 'success',
            });
            this.$emit('update', -1);
          }).catch((ee) => {
            const content = ee?.response?.data?.message ?? String(ee);
            this.isSending = false;
            this.$toast.show({
              title: 'Comentário',
              content,
              type: 'danger',
            });
            this.$emit('update');
          });
        }
      }).finally(() => {
        this.$root.$emit('refreshLogList'); // Refresh deal log list
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.card-task {
  border-radius: 8px;
  flex: 0 0 100%;
  max-width: 100%;
  transition: 0.5s;
  margin-bottom: 0;
  height: fit-content;
  min-height: auto;
  border-left: 5px solid var(--clr-yup-purple);
  &:hover {
    // transform: scale(1.02);
    box-shadow: 0px 3px 15px #0000001A;
  }
  .card-header {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    padding: .5rem;
    padding-left: .25rem;
    .options-task {
      margin-left: auto;
      small {
        display: flex;
        align-items: center;
        color: var(--gray-500);
        .material-symbols-outlined {
          margin-right: .25rem;
          font-size: .85rem;
        }
      }
      .btn {
        width: fit-content;
        height: fit-content;
        padding: 0;
        .material-symbols-outlined {
          border-radius: 50%;
          padding: 5px;
          font-size: 1.25rem;
        }
      }
      .material-symbols-outlined {
        &.done {
          background-color: var(--sent-bg) !important;
          border-color: var(--sent-fg) !important;
          color: var(--sent-fg) !important;
        }
        &.not-done {
          background-color: var(--normal-bg) !important;
          border-color: var(--normal-fg) !important;
          color: var(--normal-fg) !important;
        }
        &.expired {
          background-color: var(--urgent-bg) !important;
          border-color: var(--urgent-fg) !important;
          color: var(--urgent-fg) !important;
        }
      }
      ul {
        display: flex;
        align-items: center;
        position: relative;
        gap: 5px;
        margin-bottom: 0;
        .btn-delete-wrapper {
          position: absolute;
          top: -25px;
          right: -23px;
          .btn {
            opacity: 0;
          }
        }
      }
    }
  }
  &:hover {
    .btn-delete-wrapper {
      .btn {
        opacity: 1 !important;
      }
    }
  }
  .card-body {
    padding: .5rem;
    padding-left: .25rem;
    padding-top: 0;
    padding-bottom: 0;
    flex-basis: unset;
    flex-grow: initial;
    .task-description, .toggle-custom {
      font-size: .75rem;
    }
    .toggle-custom {
        .material-symbols-outlined {
        margin-left: 0;
        margin-right: 5px;
        transition: .5s;
        &.rotate {
          transform: rotate(-180deg);
        }
      }
    }
  }
  .card-footer {
    padding: .5rem;
    padding-left: .25rem;
    padding-top: .45rem;
    padding-bottom: .25rem;
    .list-unstyled {
      display: flex;
      gap: .5rem;
      margin-bottom: 0;
      .btn {
        color: #fff;
        font-weight: 400;
        border-radius: 8px;
        padding: .25rem;
      }
    }
    .btn-done {
      background-color: var(--sent-fg);
    }
    .btn-undone {
      background-color: var(--normal-fg);
    }
    .btn-edit {
      background-color: var(--rejected-fg);
    }
  }
  .collapse-preview a.collapsed:after {
    content: '+ Show More';
  }

  .collapse-preview a:not(.collapsed):after {
    content: '- Show Less';
  }

  .collapse-preview.collapse:not(.show) {
    display: block;
    overflow: hidden;
  }
  .backdrop-preview {
    display: none;
  }
  .collapse-preview.collapse:not(.show) ~ .backdrop-preview {
    position: relative;
    display: block;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      var(--background-2) 100%
    );
    height: 30px;
    margin-top: -30px;
  }

  .collapse-preview.collapsing, .collapse-preview.collapse:not(.show) {
    /* height = lineheight * no of lines to display */
    height: 1.8em;
  }
}
button {
  right: 5px;
  top: 3px;
  padding: 0;
  height: fit-content;
  .delete-icon, .edit-icon {
    font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 48;
    color: var(--gray-font-color-3);
    margin-left: auto;
    font-size: 18px;
    transition: 0.5s;
    // position absolute style
    background-color: var(--background-2);

    &:hover,
    &:focus {
      opacity: 1;
      color: #d5273e;
      transform: scale(1.2);
    }
    &.edit-icon:hover,
    &.edit-icon:focus {
      opacity: 1;
      color: var(--normal-fg);
      transform: scale(1.2);
    }
  }
}
</style>
