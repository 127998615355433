<template>
  <div>
    <template v-if="fetched">
      <b-dd id="dd-filter" variant="link p-0 filter-opt" toggle-class="text-decoration-none" right no-caret>
        <template #button-content>
          <span class="mr-1">{{$t('app.order-by')}} ({{$t(`app.${selectedOrderComments}-order`)}})</span><svg xmlns="http://www.w3.org/2000/svg" fill="none" role="button" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="sort-descending"><path stroke-linecap="round" stroke-linejoin="round" d="M3 4.5h14.25M3 9h9.75M3 13.5h9.75m4.5-4.5v12m0 0l-3.75-3.75M17.25 21L21 17.25" /></svg>
        </template>
        <b-dd-item @click="workspaceOrder('latest')">{{$t('app.latest-order')}}</b-dd-item>
        <b-dd-item @click="workspaceOrder('oldest')">{{$t('app.oldest-order')}}</b-dd-item>
      </b-dd>
      <ul>
        <li class="timeline-item">
          <span class="timeline-item-icon avatar-icon">
            <i class="user-name-picture" :style="{ backgroundColor: $strToColor(name) }">
              {{name | initials}}
            </i>
            <!-- <i class="avatar">
              <img src="https://assets.codepen.io/285131/hat-man.png" />
            </i> -->
          </span>
          <div class="new-comment">
            <!-- <input type="text" placeholder="Add a comment..." /> -->
            <textarea @keydown.enter.exact.prevent="() => { if(newComment.comment.length > 0) createComment()}" v-model="newComment.comment" class="form-control" placeholder="Add a comment..." name="new-comment" id="create-comment" cols="20" rows="2"></textarea>
            <!-- <p>
              <code class="w-100">{{newComment.comment.length}}</code><br>
              <code class="w-100">{{newComment.comment}}</code><br>
              <code class="w-100">{{user.users[0].name}}</code>
            </p> -->
          </div>
        </li>
        <template v-for="(detailComment, index) in commentItems">
          <comments-list v-if="detailComment" :key="detailComment.id" :item='detailComment' :index='index' @updateComment="fetch"/>
        </template>
      </ul>
      <pagination v-model="currentPage" :total-pages="pages" @change="fetch" />
    </template>
    <!-- <template v-else>
      <div class="loading-wrapper">
        <LoadingAnim />
      </div>
    </template> -->
  </div>
</template>

<script>
import Vue from 'vue';
import CommentsList from '@/components/rebranding/workflow/detail/list/CommentsList.vue';
import Pagination from '@/components/rebranding/pagination/Pagination.vue';
import axios from '@/plugins/axios';
// import LoadingAnim from '@/components/rebranding/animLottie/Loading.vue';

const webApi = axios();

export default {
  name: 'Comments',
  components: {
    CommentsList,
    Pagination,
    // LoadingAnim,
  },
  props: {
    user: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    name() {
      return this.$store?.state?.auth?.user?.name;
    },
  },
  data() {
    return {
      timeout: null,
      currentPage: 1,
      pages: 1,
      fetched: true,
      fetchedComments: false,
      selectedOrderComments: 'latest',
      newComment: {
        comment: '',
        user: '',
        created_at: '',
      },
      commentItems: [],
      /* commentItemTest: [
        {
          user: 'Erico',
          comment: 'Cumque quae velit perspiciatis assumenda, ut animi, voluptates odio ducimus ad soluta provident possimus.',
          created_at: '2023-10-17T02:06:21.000000Z',
          picture: 'https://assets.codepen.io/285131/hat-man.png',
        },
        {
          user: 'Erica',
          comment: 'Provident, quia dicta, quisquam corrupti id facilis dignissimos distinctio, et molestias numquam aliquam ab fugit a magni. Ipsam quis dolore animi ratione.',
          created_at: '2023-10-05T02:06:21.000000Z',
          picture: 'https://assets.codepen.io/285131/winking-girl.png',
        },
        {
          user: 'Maria',
          comment: 'Cumque quae velit perspiciatis assumenda, ut animi, voluptates odio ducimus ad soluta provident possimus.',
          created_at: '2023-08-23T02:06:21.000000Z',
          picture: 'https://assets.codepen.io/285131/smiling-girl.png',
        },
      ], */
    };
  },
  filters: {
    initials(name) {
      let [f, l] = name.replace(/\s(do|de|da)\s/gi, ' ').split(/\s+/, 2);

      f = `${f || ' '}`.toUpperCase();
      l = `${l || ' '}`.toUpperCase();

      return `${f[0]}${l[0]}`;
    },
  },
  methods: {
    workspaceOrder(e) {
      this.selectedOrderComments = e;
      localStorage.setItem('commentsOrder', e);
      this.fetch(this.currentPage);
    },
    fetch(page) {
      this.fetched = false;
      this.currentPage = page;
      const id = this.$route.params.id;

      console.log('comentarios', id);

      webApi.get(`/pipelines/deals/${id}/comments?page=${this.currentPage}&sort=${this.selectedOrderComments}`).then(
        (response) => {
          console.log('Comentários response: ', response);
          this.commentItems = response.data.data;
          this.pages = response.data.last_page;
        },
        (error) => {
          this.$toast.show({
            title: 'Erro ao consultar',
            content: error.message,
            type: 'danger',
          });
        },
      ).finally(() => {
        this.fetched = true;
      });
    },
    createComment() {
      this.fetchedComments = false;
      console.log('Comment isBlank: ', this.isBlank(this.newComment.comment));
      if (this.isBlank(this.newComment.comment) === false) {
        webApi.post('/pipelines/comments', {
          deal: this.$route.params.id,
          body: this.newComment.comment,
        }).then(
          (response) => {
            console.log(`Comments: then ->\n${response}`);
            this.fetch(1);
          },
          (error) => {
            console.error(`Comments: error ->\n${error}`);
            this.content = error;
            this.$toast.show({
              title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
              content: error.message,
              type: 'danger',
            });
          },
        ).finally((final) => {
          console.log(`Comments: finally ->\n${final}`);
          this.newComment.comment = '';
          this.fetchedComments = true;
        });
      }
    },
    isBlank(str) {
      return (!str || /^\s*$/.test(str));
    },
    createCommentTest() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        const date = new Date();
        const utcDate = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds(), date.getUTCMilliseconds()));
        // Vue.set(this.newComment.comment, );
        const obj = {};
        obj.user = this.name;
        // obj.comment = this.newComment.comment.replace(new RegExp(/\r?\n/, 'g'), '<br />');
        obj.comment = this.newComment.comment;
        // obj.comment = obj.comment.replace(new RegExp(/\r?\n/, 'g'), '<br />');
        obj.created_at = utcDate;
        this.commentItemTest.push(obj);
        this.commentItemTest.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        this.newComment.comment = '';
      }, 1000);
    },
  },
  mounted() {
    if (!localStorage.getItem('commentsOrder')) {
      console.log('Has order previously selected');
      this.selectedOrderComments = 'latest';
    } else {
      console.log('Has not: ', localStorage.getItem('commentsOrder'));
      this.selectedOrderComments = localStorage.getItem('commentsOrder');
    }
    this.fetch(1);
  },
};
</script>
<style lang="scss">
.filter-opt {
  display: flex;
  * {
    color: var(--gray-font-color-6);
    font-weight: 400;
  }
}
.darkmode {
  .comments-row {
    .timeline {
      border-left: 2px solid var(--background-2);
    }
    .timeline-item-icon {
      box-shadow: 0 0 0 6px var(--background-3);
    }
  }
}
.comments-row {
  .timeline {
    width: 85%;
    max-width: 700px;
    // margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    padding: 32px 0 32px 32px;
    border-left: 2px solid var(--c-grey-200);
    font-size: 1.125rem;
  }

  .timeline-item {
    display: flex;
    gap: 24px;
    & + * {
      margin-top: 24px;
    }
    & + .extra-space {
      margin-top: 48px;
    }
  }

  .new-comment {
    width: 100%;
    input, textarea {
      border: 1px solid var(--c-grey-200);
      border-radius: 6px;
      height: 48px;
      padding: 0 16px;
      width: 100%;
      &::placeholder {
        color: var(--c-grey-300);
      }

      &:focus {
        border-color: var(--c-grey-300);
        outline: 0; // Don't actually do this
        box-shadow: 0 0 0 4px var(--c-grey-100);
      }
    }
    textarea {
      padding-top: .5rem;
    }
  }

  .timeline-item-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-left: -92px;
    flex-shrink: 0;
    overflow: hidden;
    box-shadow: 0 0 0 6px #fff;
    pointer-events: none;
    svg {
      width: 20px;
      height: 20px;
    }

    &.faded-icon {
      background-color: var(--c-grey-100);
      color: var(--c-grey-400);
    }

    &.filled-icon {
      background-color: var(--c-blue-500);
      color: #fff;
    }
  }

  .timeline-item-description {
    display: flex;
    padding-top: 6px;
    gap: 8px;
    color: var(--c-grey-400);

    img {
      flex-shrink: 0;
    }
    a {
      color: var(--c-grey-500);
      font-weight: 500;
      text-decoration: none;
      &:hover,
      &:focus {
        outline: 0; // Don't actually do this
        color: var(--c-blue-500);
      }
    }
  }

  .commented-at {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .user-name-picture {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    color: #fff !important;
    border-radius: 50%;
    font-weight: 400;
    font-size: inherit;
    font-style: normal;
    &.small {
      width: 32px;
      height: 32px;
    }
  }

  .avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden;
    aspect-ratio: 1 / 1;
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    &.small {
      width: 28px;
      height: 28px;
    }

    img {
      object-fit: cover;
    }
  }

  .comment {
    position: relative;
    margin-top: 12px;
    color: var(--c-grey-500);
    border: 1px solid var(--c-grey-200);
    box-shadow: 0 4px 4px 0 var(--c-grey-100);
    border-radius: 6px;
    padding: 16px;
    font-size: 1rem;

    p {
      margin-bottom: 0;
    }
  }

  .button {
    border: 0;
    padding: 0;
    display: inline-flex;
    vertical-align: middle;
    margin-right: 4px;
    margin-top: 12px;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    height: 32px;
    padding: 0 8px;
    background-color: var(--c-grey-100);
    flex-shrink: 0;
    cursor: pointer;
    border-radius: 99em;

    &:hover {
      background-color: var(--c-grey-200);
    }

    &.square {
      border-radius: 50%;
      color: var(--c-grey-400);
      width: 32px;
      height: 32px;
      padding: 0;
      svg {
        width: 24px;
        height: 24px;
      }

      &:hover {
        background-color: var(--c-grey-200);
        color: var(--c-grey-500);
      }
    }
  }

  .show-replies {
    color: var(--c-grey-300);
    background-color: transparent;
    border: 0;
    padding: 0;
    margin-top: 16px;
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 1rem;
    cursor: pointer;
    svg {
      flex-shrink: 0;
      width: 24px;
      height: 24px;
    }

    &:hover,
    &:focus {
      color: var(--c-grey-500);
    }
  }

  .avatar-list {
    display: flex;
    align-items: center;
    & > * {
      position: relative;
      box-shadow: 0 0 0 2px #fff;
      margin-right: -8px;
    }
  }
}
</style>
