<template>
    <b-sidebar id="sidebar-right-add-products" ref="addTaskSide" :title="$t('catalog.create')" no-header right backdrop shadow>
      <div class="col sidebar-header">
        <span v-b-toggle.sidebar-right-add-products class="material-symbols-outlined arrow-back">keyboard_double_arrow_left</span>
        <p>Criar produto</p>
        <!-- <span class="material-symbols-outlined">chevron_right</span>
        <p class="text-yup">{{columnTitle}}</p> -->
        <span v-b-toggle.sidebar-right-add-products class="material-symbols-outlined sidebar-close">close</span>
      </div>
      <div v-if="false" class="col sidebar-item type-column">
        <div class="title-task">
          <span class="label-sidebar-item">Tipo</span>
          <select-input
            class="w-100"
            label="text"
            v-model="taskStatus"
            :reduce="(type) => type.value"
            :options="type"
            :clearable="false"
            :editable="false"
          />
        </div>
      </div>
      <div class="col sidebar-item title-column">
        <span class="label-sidebar-item">{{$tc('app.title', 1)}}</span>
        <input class="form-control" v-model="taskTitle" type="text">
      </div>
      <div class="col sidebar-item description-column">
        <span class="label-sidebar-item">{{$t('app.description')}}</span>
        <input class="form-control" v-model="taskDescription" type="text">
      </div>
      <div class="col sidebar-item amount-column">
        <span class="label-sidebar-item">Valor</span>
        <input class="form-control" placeholder="R$ 0,00" v-money="money" maxlength="13" v-model="taskAmount" type="text">
      </div>
      <!-- <div v-show="false" v-if="users.length > 0" class="col sidebar-item assign-user-column">
        <span class="label-sidebar-item">{{$t('workflow.workspace.card.assign')}}</span>
        <svg @click="addingUser = !addingUser" data-v-1c0b824d="" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="heroicon add"><path data-v-1c0b824d="" stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
        <select-input
          v-if="addingUser"
          multiple
          label="name"
          class="w-100 add-user"
          :reduce="(itemTask) => itemTask"
          v-model="userSelect"
          @input="addUser()"
          :options="users"
          :clearable="false"
        >
          <template #selected-option="{ name }">
            <div style="display: flex; align-items: baseline">
              <strong>{{ name }}</strong>
            </div>
          </template>
        </select-input>
        <div class="w-100 profile-wrapper">
          <template v-for="user in userSelect">
            <div
              class="profile"
              :key="`carduser:${user.id}`"
              :title="`teamuser:${user.id}`"
              :style="{ backgroundColor: $strToColor(user.name) }">
                {{user.name | initials}}
              </div>
          </template>
        </div>
      </div> -->
      <div v-if="false" class="col sidebar-item date-column">
        <span class="label-sidebar-item">Data de vencimento</span>
        <Calendar id="icon" ref="calendarDate" v-model="taskDate" :value="taskDate" dateFormat="dd/mm/yy" :showTime="true" :showIcon="true" />
      </div>
      <div class="col btn-wrapper">
        <button class="btn btn-outline-primary mt-3" v-b-toggle.sidebar-right-add-products>{{$t('app.cancel')}}</button>
        <button class="btn btn-primary mt-3 ml-auto" @click="addProduct()" :class="{'qt-loader qt-loader-mini qt-loader-right':isSending}" :disabled="isSending">{{$t('app.save')}}</button>
      </div>
    </b-sidebar>
</template>
<script>
// Axios
import axios from '@/plugins/axios';

// Primevue
import 'primevue/resources/themes/saga-blue/theme.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'primevue/resources/primevue.min.css';
import Calendar from 'primevue/calendar';
import $ from 'jquery';

// Select
import SelectInput from '@/components/SelectInput.vue';

const webApi = axios();

export default {
  name: 'TasksSideBar',
  components: {
    SelectInput,
    Calendar,
  },
  /* props: {
    users: {
      type: Array,
      default: () => ({}),
    },
  }, */
  filters: {
    initials(name) {
      let [f, l] = name.replace(/\s(do|de|da)\s/gi, ' ').split(/\s+/, 2);

      f = `${f || ' '}`.toUpperCase();
      l = `${l || ' '}`.toUpperCase();

      return `${f[0]}${l[0]}`;
    },
  },
  data() {
    return {
      addingUser: false,
      taskOwner: '',
      taskTitle: '',
      taskDescription: '',
      taskAmount: '',
      taskSubject: '',
      taskType: '',
      taskStatus: '',
      taskDate: '',
      timeout: null,
      searchQuery: '',
      owners: [],
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: '',
        precision: 2,
        masked: false,
      },
      type: [{ text: 'Tarefa', value: 'task' }, { text: 'Normal', value: 'medium' }, { text: 'Alto', value: 'high' }, { text: 'Urgente', value: 'urgent' }],

      isSending: false,

      // userAndDepCard: [],
      userSelect: '',
      userTaskEdit: [],
    };
  },
  methods: {
    addProduct() {
      console.log('Adding product...');
      const price = this.taskAmount.replace(/[R$ ]/g, '').replace(/[.]/g, '').replace(/[,]/g, '.');
      console.log('The price: ', price);
      if (!this.taskTitle) {
        this.$toast.show({
          title: `${this.$t('sms.infos.filters.status.lbl-error')}`,
          content: 'Insira o nome do produto!',
          type: 'danger',
        });
      } else if (!this.taskDescription) {
        this.$toast.show({
          title: `${this.$t('sms.infos.filters.status.lbl-error')}`,
          content: 'Insira uma descrição!',
          type: 'danger',
        });
      } else if (!price || price === '0.00') {
        this.$toast.show({
          title: `${this.$t('sms.infos.filters.status.lbl-error')}`,
          content: 'Insira um valor!',
          type: 'danger',
        });
      } else {
        webApi
          .post('/pipelines/offerings', {
            title: this.taskTitle,
            description: this.taskDescription,
            amount: price,
          })
          .then(
            (response) => {
              console.log('Product added: ', response);
              this.$toast.show({
                title: this.$t('catalog.registered.popup.success'),
                content: '',
                type: 'success',
              });
              this.toggleSidebar('sidebar-right-add-products');
              this.$emit('update');
              /* if ($('#v-pills-products-tab[data-toggle="pill"]')) {
                const tabName = $('#v-pills-products-tab[data-toggle="pill"]');
                tabName.tab('show');
                tabName.on('shown.bs.tab', (e) => {
                  e.target; // newly activated tab
                  e.relatedTarget; // previous active tab
                });
              } */
            },
            (error) => {
              this.fetched = true;
              console.error({ error });
              this.content = error;
              this.$toast.show({
                title: `${this.$t('sms.infos.filters.status.lbl-error')} ${
                  error.code
                }`,
                content: error.message,
                type: 'danger',
              });
            },
          );
      }
    },
    toggleSidebar(id) {
      this.$root.$emit('bv::toggle::collapse', id);
    },
    fetchUsersDepBoard(search, isEdit) {
      clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        if (isEdit) {
          this.userAndDepCardEdit = [];
        } else {
          this.userAndDepCard = [];
        }

        /* webApi.get(`/workflows/boards/${this.$route.params.id}/team?query=${encodeURIComponent(search)}`).then(
          (response) => {
            console.log('Fetch user n dep response: ', response, 'query: ', search);
            if (isEdit) {
              this.userAndDepCardEdit = response.data;
            } else {
              this.userAndDepCard = response.data;
            }
            console.log('Users array: ', this.userAndDepCard);
          },
          (error) => {
            this.content = error;
          },
        ); */
      }, 1000);
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep{
  .vs__selected {
    background-color: var(--clr-yup-purple-hover-transparent);
    border-color: var(--clr-yup-purple-hover-transparent);
    border-radius: 8px;
    color: var(--clr-yup-purple);
  }
  button.vs__deselect span {
    color: var(--clr-yup-purple);
  }
}
.profile-wrapper {
  display: flex;
  .profile {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    flex-shrink: 0;
    overflow: hidden;
    box-shadow: 0 0 0 4px var(--background-2);
    pointer-events: none;
    color: #fff;
    svg {
      width: 20px;
      height: 20px;
    }

    &.faded-icon {
      background-color: var(--c-grey-100);
      color: var(--c-grey-400);
    }

    &.filled-icon {
      background-color: var(--c-blue-500);
      color: #fff;
    }
  }
}
.card-task {
  border-radius: 8px;
  flex: 0 0 31%;
  max-width: 31%;
  transition: 0.5s;
  margin-bottom: 0;
  height: fit-content;
  min-height: 210px;
  &:hover {
    transform: scale(1.02);
  }
  .card-header {
    display: flex;
    align-items: center;
    .options-task {
      margin-left: auto;
      .btn {
        width: fit-content;
        height: fit-content;
        padding: 0;
      }
      .material-symbols-outlined {
        border-radius: 50%;
        padding: 3px;
        &.done {
          background-color: var(--sent-bg) !important;
          border-color: var(--sent-fg) !important;
          color: var(--sent-fg) !important;
        }
        &.not-done {
          background-color: var(--urgent-bg) !important;
          border-color: var(--urgent-fg) !important;
          color: var(--urgent-fg) !important;
        }
      }
      ul {
        display: flex;
        align-items: center;
        gap: 5px;
        margin-bottom: 0;
      }
    }
  }
  .card-body {
    padding-top: 0;
  }
  .collapse-preview a.collapsed:after {
    content: '+ Show More';
  }

  .collapse-preview a:not(.collapsed):after {
    content: '- Show Less';
  }

  .collapse-preview.collapse:not(.show) {
    display: block;
    /* height = lineheight * no of lines to display */
    height: 4.5em;
    overflow: hidden;
  }
  .backdrop-preview {
    display: none;
  }
  .collapse-preview.collapse:not(.show) ~ .backdrop-preview {
    position: relative;
    display: block;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    height: 30px;
    margin-top: -30px;
  }

  .collapse-preview.collapsing {
    height: 4.5em;
  }
}
</style>
