<template>
  <li class="timeline-item" v-if="item">
    <!-- <code>{{item}}</code> -->
    <span class="timeline-item-icon filled-icon">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
        <path fill="none" d="M0 0h24v24H0z" />
        <path fill="currentColor" d="M6.455 19L2 22.5V4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H6.455zM7 10v2h2v-2H7zm4 0v2h2v-2h-2zm4 0v2h2v-2h-2z" />
      </svg>
    </span>
    <div class="timeline-item-wrapper">
      <div class="timeline-item-description">
        <i v-if="item.user.picture" class="avatar small">
          <img :src="item.picture" />
        </i>
        <i v-else class="user-name-picture small" :style="{ backgroundColor: $strToColor(item.user.name) }">
          {{item.user.name | initials}}
        </i>
        <span class="commented-at"><a href="#">{{item.user.name}}</a> {{$t('workflow.workspace.comments.did')}} <time :datetime="item.created_at">{{item.created_at | relativeDate}}</time></span>
      </div>
      <div class="comment">
        <p v-html="item.body.replace(new RegExp(/\r?\n/, 'g'), '<br />')"></p>
        <button v-if="item.user.id === userId" class="btn" @click="deleteComment(item.id)"><span class="material-symbols-outlined delete-icon">delete_forever</span></button>
        <!-- <button class="button">👏 2</button>
        <button class="button | square">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
            <path fill="none" d="M0 0h24v24H0z" />
            <path fill="currentColor" d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zM7 12a5 5 0 0 0 10 0h-2a3 3 0 0 1-6 0H7z" />
          </svg>
        </button> -->
      </div>
    </div>
  </li>
</template>
<script>
import Swal from 'sweetalert2';
import axios from '@/plugins/axios';

const webApi = axios();

export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    index: {
      type: Number,
    },
  },
  computed: {
    userId() {
      return this.$store?.state?.auth?.user?.id;
    },
  },
  filters: {
    initials(name) {
      let [f, l] = name.replace(/\s(do|de|da)\s/gi, ' ').split(/\s+/, 2);

      f = `${f || ' '}`.toUpperCase();
      l = `${l || ' '}`.toUpperCase();

      return `${f[0]}${l[0]}`;
    },
  },
  methods: {
    deleteComment(id) {
      Swal.fire({
        title: this.$t('workflow.workspace.comments.swal.remove-custom-field'),
        text: this.$t('workflow.workspace.comments.swal.sure'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('generic-str.yes'),
        cancelButtonText: this.$t('generic-str.no'),
      }).then((result) => {
        if (result.value) {
          webApi.delete(`/pipelines/comments/${id}`).then((response) => {
            console.log(`Comments: delete ->\n${response}`);
            this.$toast.show({
              title: this.$t('generic-str.success'),
              content: 'Comentário deletado com sucesso!',
              type: 'success',
            });
            this.$emit('updateComment');
          }).catch((ee) => {
            const content = ee?.response?.data?.message ?? String(ee);
            this.isSending = false;
            this.$toast.show({
              title: 'Comentário',
              content,
              type: 'danger',
            });
            this.$emit('updateComment');
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
button {
  display: flex;
  position: absolute;
  right: 5px;
  top: 3px;
  padding: 0;
  height: fit-content;
  .delete-icon {
    font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 48;
    color: var(--gray-font-color-3);
    margin-left: auto;
    font-size: 18px;
    transition: 0.5s;
    opacity: 0;

    &:hover,
    &:focus {
      // color: #d5273e;
      transform: scale(1.2);
    }
  }
}
::v-deep {
  &.comment:hover {
    .delete-icon {
      opacity: 1;
      color: #d5273e;
    }
  }
}
</style>
